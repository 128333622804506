import forEach from 'lodash/forEach';
import keys from 'lodash/keys';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';

const initialState = {
    directory: null,
    eHealthOrgs: null,
    exchanges: null,
};

class DirectoryStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);
    }

    updateDirectory(state) {
        const directory = JSON.parse(JSON.stringify(state));
        const exchanges = {};
        const eHealthOrgs = [];

        forEach(directory, item => {
            exchanges[item.dataSource] = true;

            if (item.dataSource === 'eHealthExchangeLegacyFHIR') {
                eHealthOrgs.push(item);
            }
        });

        this.state.directory = JSON.parse(JSON.stringify(state));
        this.state.exchanges = keys(exchanges);
        this.state.eHealthOrgs = eHealthOrgs;
        this.emitChange();
    }
}

DirectoryStore.storeName = 'DirectoryStore';
DirectoryStore.handlers = {
    UPDATE_DIRECTORY: 'updateDirectory',
    LOGOUT: 'resetState',
};

export default DirectoryStore;
