import LOCATIONS from './data/locations';

export default function loadLocations({ request, resolve, reject }) {
    const parts = request.url.split('/');
    const locations = LOCATIONS[`${parts[parts.length - 1]}`];

    if (locations) {
        resolve(locations);
    } else {
        reject(404);
    }
}
