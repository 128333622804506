function printHtml({ html, title, styles }) {
    const printWindow = window.open('', 'PRINT', 'height=600,width=800');
    printWindow.document.write(`
    <html>
    <head>
    <title>
        ${title}
    </title>
    <style>
        ${styles}
    </style>
    </head>
    <body>
        ${html}
    </body>
    </html>
            `);
    printWindow.document.close();
    printWindow.focus();

    setTimeout(() => {
        printWindow.print();
        printWindow.close();
    }, 10);
}

export default printHtml;
