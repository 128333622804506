import get from 'lodash/get';
import moment from 'moment';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import formatDate from '../common/util/format-date';
import {
    formatPatientName,
    formatGender,
    formatAddress,
    formatPhoneNumber,
} from '../common/util/format-patient-fields';

//
//  State example:
//  history: [
//     {
//       "originalSearchDate": "2020-04-05T19:49:02Z",
//       "patientDemographics": {
//         "firstName": "",
//         "lastName": "",
//         "middleName": "",
//         "suffix": null,
//         "gender": "M",
//         "dob": "20200104",
//         "address1": "",
//         "address2": "",
//         "city": "",
//         "state": null,
//         "zip": "",
//         "homePhone": null,
//         "cellPhone": null,
//         "workPhone": null,
//         "ssn": ""
//       },
//       "userDemographics": {
//         "firstName": "",
//         "lastName": "",
//         "middleName": null,
//         "suffix": null,
//         "gender": null,
//         "dob": null,
//         "address1": null,
//         "address2": null,
//         "city": null,
//         "state": null,
//         "zip": null,
//         "homePhone": null,
//         "cellPhone": null,
//         "workPhone": null,
//         "ssn": null
//       },
//       "parentRequestId": "",
//       "tenantId": "",
//       "activationId": null,
//       "facilityId": "",
//       "expiresOn": ""
//     }
//   ]
// patientResults: [
//     {
//       "requestId": "a1f2396e-a675-454d-bc74-1fa8a0cf8543",
//       "oid": "2.16.840.1.113883.3.89.100.20.30.1",
//       "dateReceived": "2020-04-06T13:04:32Z",
//       "patientId": [
//         {
//           "patientId": "1055417180",
//           "assigningAuthority": "2.16.840.1.113883.3.89.100.20.30.1"
//         }
//       ],
//       "patient": {
//         "firstName": "",
//         "lastName": "",
//         "middleName": null,
//         "suffix": null,
//         "gender": "M",
//         "dob": "19600303",
//         "address1": "",
//         "address2": null,
//         "city": "",
//         "state": "",
//         "zip": "",
//         "homePhone": "",
//         "cellPhone": null,
//         "workPhone": null,
//         "ssn": ""
//       },
//       "parentRequestId": "0f26029f-e304-4708-888e-418e81874461",
//       "tenantId": "2b03c195-d9ca-405a-9492-778cc668cae9",
//       "activationId": null,
//       "facilityId": "87d4fd8b-d4aa-4241-9b81-b333761e994a",
//       "expiresOn": "2020-04-08T01:41:52Z"
//     }
//   ]
const initialState = {
    patientRequests: [],
    patientRequestsComplete: false,
    isLoading: false,
};

class SearchHistoryStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);
    }

    getPatientRequests() {
        const state = this.getState();
        const history = get(state, 'patientRequests', []);
        return history;
    }

    setPatientRequests(history) {
        const state = this.getState();
        const newHistory = history.map(item => {
            const { patientDemographics, originalSearchDate } = item;
            const {
                firstName,
                middleName,
                lastName,
                dob,
                gender,
                address1,
                address2,
                city,
                state: stateName,
                zip,
                homePhone,
            } = patientDemographics;

            const result = { ...item };
            result.formattedPatient = {};
            result.formattedPatient.fullName = formatPatientName({
                firstName,
                middleName,
                lastName,
            });
            result.formattedPatient.dob = formatDate(dob);
            result.formattedPatient.gender = formatGender(gender);
            result.formattedPatient.address = formatAddress({
                address1,
                address2,
                city,
                state: stateName,
                zip,
            });
            result.formattedPatient.homePhone = formatPhoneNumber(
                homePhone || '',
            );
            const searchDateTime = moment.utc(originalSearchDate);
            const localSearchDate = searchDateTime.local();
            result.formattedPatient.searchDate = formatDate(
                localSearchDate,
                'MM/DD/YYYY h:mm a',
            );
            return result;
        });
        state.patientRequests = newHistory;
        state.patientRequestsComplete = true;
        this.setState(state);
    }

    getIsLoading() {
        return get(this.getState(), 'isLoading');
    }

    setIsLoading(isLoading) {
        const state = this.getState();
        state.isLoading = isLoading;
        this.setState(state);
    }
}

SearchHistoryStore.storeName = 'SearchHistoryStore';
SearchHistoryStore.handlers = {
    SET_HISTORY_PATIENT_REQUESTS: 'setPatientRequests',
    SET_HISTORY_IS_LOADING: 'setIsLoading',
    CLEAR_SEARCH_HISTORY: 'resetState',
    LOGOUT: 'resetState',
};

export default SearchHistoryStore;
