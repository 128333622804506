import PropTypes from 'prop-types';

export const localizationShape = {
    patientDemoForm: {
        firstNameFieldLabel: PropTypes.string.isRequired,
        middleNameFieldLabel: PropTypes.string.isRequired,
        lastNameFieldLabel: PropTypes.string.isRequired,
        dateOfBirthFieldLabel: PropTypes.string.isRequired,
        genderFieldLabel: PropTypes.string.isRequired,
        zipCodeFieldLabel: PropTypes.string.isRequired,
        address1FieldLabel: PropTypes.string.isRequired,
        address2FieldLabel: PropTypes.string.isRequired,
        cityFieldLabel: PropTypes.string.isRequired,
        stateFieldLabel: PropTypes.string.isRequired,
        ssnFieldLabel: PropTypes.string.isRequired,
        phoneFieldLabel: PropTypes.string.isRequired,
    },
    documentResults: {
        documents: PropTypes.string,
        medications: PropTypes.string,
        conditions: PropTypes.string,
        more: PropTypes.string,
        clearSelection: PropTypes.string,
        documentFilterType: PropTypes.string,
        table: PropTypes.shape({
            title: PropTypes.string.isRequired,
            source: PropTypes.string.isRequired,
            serviceTime: PropTypes.string.isRequired,
            author: PropTypes.string.isRequired,
            authorInstitution: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
        }),
    },
    patientDemoResults: {
        table: {
            gender: PropTypes.string.isRequired,
            patient: PropTypes.string.isRequired,
            dob: PropTypes.string.isRequired,
            address: PropTypes.string.isRequired,
            source: PropTypes.string.isRequired,
            phone: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
        },
    },
    acfSelection: {
        title: PropTypes.string.isRequired,
        searchPlaceholder: PropTypes.string.isRequired,
        continueButton: PropTypes.string.isRequired,
        noLocations: PropTypes.string.isRequired,
    },
    documentView: {
        errorLabel: PropTypes.string.isRequired,
    },
    searchHistory: {
        table: {
            name: PropTypes.string.isRequired,
            dob: PropTypes.string.isRequired,
            gender: PropTypes.string.isRequired,
            address: PropTypes.string.isRequired,
            phone: PropTypes.string.isRequired,
            lastAccessed: PropTypes.string.isRequired,
        },
    },
};

export default PropTypes.shape(localizationShape).isRequired;
