import get from 'lodash/get';
import QueryType from '../common/query/query-type';
import { issueQuery } from '../common/query/query-helper';

export async function setDocumentAsFailed(actionContext) {
    actionContext.dispatch('SET_RETRIEVE_DOCUMENT_STATUS', { isError: true });
}

export async function retrieveDocuments(actionContext, selectedDocument) {
    const buildRequestData = ({
        facilityId,
        facilityName,
        queryId,
        user,
        originalPdRequestId,
    }) => {
        const documents = [
            {
                repositoryId: get(selectedDocument, 'repositoryId'),
                documentId: get(selectedDocument, 'documentId'),
                dateCreated: get(selectedDocument, 'dateCreated'),
                oid: get(selectedDocument, 'oid'),
            },
        ];

        const requestingDemographics = {
            firstName: get(user, 'firstName'),
            lastName: get(user, 'lastName'),
        };

        const orgPatients = [
            {
                oid: get(selectedDocument, 'oid'),
                patientId: get(selectedDocument, 'patientId'),
            },
        ];

        setTimeout(() => {
            const query = actionContext
                .getStore('QueryStore')
                .getQuery(queryId);

            if (!query) {
                return;
            }

            const results = get(query, 'resultRequests.length', 0);
            const numExpectedResults = get(query, 'numExpectedResults', 0);

            if (!(numExpectedResults > 0 && results >= numExpectedResults)) {
                // we have not returned the results, timeout the query
                actionContext.socket.removeHandlers({ event: queryId });
                setDocumentAsFailed(actionContext);
            }
        }, 1000 * 60 * 5); // 5 minutes

        return {
            facilityId,
            facilityName,
            originalPdRequestId,
            requestId: queryId,
            documents,
            tenantId: user.tenantId,
            orgPatients,
            requestingUser: {
                demographics: requestingDemographics,
            },
        };
    };

    actionContext.dispatch('START_RD_TRANSFORM_LOADING', selectedDocument);

    issueQuery(actionContext, QueryType.RETRIEVE_DOCUMENT, buildRequestData);
}

export async function endViewingDocument(actionContext) {
    actionContext.dispatch('END_VIEWING_DOCUMENT');
}
