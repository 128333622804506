import get from 'lodash/get';

function retrieveSearchHistorySuccess(context, { response }) {
    context.dispatch('SET_HISTORY_PATIENT_REQUESTS', response.data);
}
retrieveSearchHistorySuccess.displayName = 'retrieveSearchHistorySuccess';

export default config => ({
    serviceName: 'searchHistory.retrievePatientRequests',
    authenticate: true,
    config: {
        method: 'GET',
        url: get(
            config,
            'endpoints.pulse.searchHistory.retrievePatientRequests',
        ),
    },
    onSuccess: retrieveSearchHistorySuccess,
});
