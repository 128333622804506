export default {
    id: '',
    version: '1.0.0',
    schema: '1.0.0',
    configuration: {
        example: {
            path: 'default value',
        },
        searchHistoryExpirationHours: 36,
        disableSearchHistory: false,
        medicationsTimeout: 45000,
    },
};
