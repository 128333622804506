import get from 'lodash/get';

export default config => ({
    serviceName: 'tenant.activation.retrieveActivationTargets',
    authenticate: true,
    config: {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET',
        url: get(config, 'endpoints.pulse.tenant.activationTarget.retrieve'),
    },
});
