import merge from 'lodash/merge';
import split from 'lodash/split';

/**
 * Returns the domain for APIs
 * Example domain: dev-ainqapps
 * See https://wiki.ainq.com/display/SaaS/Platform+URI+Composition
 */
function getDomain() {
    const domainSections = split(window.location.hostname, '.');
    domainSections.pop(); // Pop off "com"

    return domainSections.pop() || 'dev-ainqapps';
}

const DEFAULT_CONFIG = {
    eventHandlers: [],
    url: `https://api.${getDomain()}.com/patientDiscoveryResponse`,
    // url: `http://localhost:80/patientDiscoveryResponse`,
    // url: 'http://localhost:80/medicationsResponse',
    connectionOptions: null,
    autoConnect: true,
    connectImmediately: false,
    // onError: (context, error, errorType) => {
    //     if (error.message !== 'transport close') {
    //         context.notify.warning({
    //             message: `Socket error of type ${errorType}: ${error.message} `,
    //         });
    //     }
    // },
    mocks: {
        enabled: false,
    },
};

export default (config = {}) => merge(DEFAULT_CONFIG, config);
