/* eslint-disable react/jsx-key */
import React from 'react';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import PropTypes from 'prop-types';
import { SimpleTable } from '@audacious/components/components/Table';
import Spinner from '@audacious/components/components/Spinner';
import Icon from '@audacious/components/components/Icon';
import { faCheckCircle } from '@audacious/icons/solid/faCheckCircle';
import { faExclamationCircle } from '@audacious/icons/solid/faExclamationCircle';

import { patientShape } from '../../../../prop-types/patient-results';
import QueryDocumentStatus from '../../../../../common/query/query-document-status';

import './patient-demo-result-table.scss';

function createStatusCell(status) {
    if (status === QueryDocumentStatus.LOADING) {
        return (
            <div className="retrieving-documents">
                <Spinner size="sm" />
            </div>
        );
    }

    if (
        status === QueryDocumentStatus.NO_RESULTS ||
        status === QueryDocumentStatus.ERROR ||
        status === QueryDocumentStatus.TIMED_OUT
    ) {
        return (
            <>
                <Icon icon={faExclamationCircle} color="danger" />
                {` ${status || null}`}
            </>
        );
    }

    if (status === QueryDocumentStatus.SUCCESS) {
        return (
            <>
                <Icon icon={faCheckCircle} color="success" />
                {` ${status || null}`}
            </>
        );
    }

    return null;
}

function handleIsHeaderSelected(items) {
    let isSelected = true;

    forEach(items, item => {
        if (!item.selected) {
            isSelected = false;
        }
    });

    return isSelected;
}
class PatientDemoResultTable extends React.Component {
    constructor(props) {
        super(props);

        this.handleRowCheck = this.handleRowCheck.bind(this);
        this.handleHeaderSelect = this.handleHeaderSelect.bind(this);
    }

    handleRowCheck(item, selected, rowIndex) {
        const { onChange } = this.props;

        if (isFunction(onChange)) {
            onChange(item, selected, rowIndex);
        }
    }

    handleHeaderSelect(items, checked) {
        const { onSelectAll } = this.props;

        onSelectAll(checked);
    }

    render() {
        const { localization, items } = this.props;

        const columns = [
            {
                headerBody: get(localization, 'patient'),
                path: 'patient.fullName',
                cellValuePath: 'patient.fullName',
                sortable: true,
            },
            {
                headerBody: get(localization, 'dob'),
                cellValuePath: 'patient.dob',
                cellSortPath: 'patient.dobTimestamp',
                sortable: true,
                width: '130px',
            },
            {
                headerBody: get(localization, 'gender'),
                cellValuePath: 'patient.gender',
                sortable: true,
                width: '120px',
            },
            {
                headerBody: get(localization, 'address'),
                cellValuePath: 'patient.fullAddress',
                sortable: true,
            },
            {
                headerBody: get(localization, 'phone'),
                cellValuePath: 'patient.homePhone',
                sortable: true,
            },
            {
                headerBody: get(localization, 'source'),
                cellValuePath: 'patient.source',
                sortable: true,
            },
            {
                headerBody: get(localization, 'status'),
                cellValuePath: 'status',
                sortable: true,
                width: '140px',
                renderCell: cellValue => (
                    <div className="query-document-status">
                        {createStatusCell(cellValue)}
                    </div>
                ),
            },
        ];

        return (
            <SimpleTable
                items={items}
                columns={columns}
                maxHeight={261}
                minWidth={1000}
                onRowSelect={this.handleRowCheck}
                isRowSelected={item => item.selected}
                selectable
                selfContained
                headerSelectable
                isHeaderSelected={handleIsHeaderSelected}
                onHeaderSelect={this.handleHeaderSelect}
            />
        );
    }
}

PatientDemoResultTable.propTypes = {
    localization: PropTypes.objectOf(PropTypes.string).isRequired,
    items: PropTypes.arrayOf(patientShape),
    onChange: PropTypes.func,
    onSelectAll: PropTypes.func.isRequired,
};

PatientDemoResultTable.defaultProps = {
    items: null,
    onChange: null,
};

export default PatientDemoResultTable;
