import isNil from 'lodash/isNil';
import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';

const initialState = {};

/**
 * @Example : this.state
 *  state = {
 *      user: {
 *          name: 'name',
 *          firstName: 'John',
 *          lastName: 'Smith',
 *          userId: '12345678abcdefg',
 *          nameidentifier: '123456'
 *          roles: {
 *              system_admin: [
 *                  'tenant:create',
 *                  'tenant:read',
 *                  'tenant:update',
 *                  'tenant:delete',
 *              ]
 *          }
 *      },
 *      auth: {
 *          authToken: 'abcdefg12345678',
 *          expirationDate: '',
 *      },
 *      activeFacility: {
 *          facilityCode: 'gdngdbibi',
 *          locationId: 'gdgs'
 *          facilityId: 'gdnjkngj'
 *          facilityName: 'Test Facility',
 *          tenantId: 'nfsbbs',
 *      },
 *      loginErrors: [
 *          { param: "username", msg: "Login Error" }
 *      ]
 *  }
 */

const STORAGE_KEY = 'userState';

class UserStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);

        try {
            const localUserState = sessionStorage.getItem(STORAGE_KEY);
            this.state = JSON.parse(localUserState);
        } catch (err) {
            this.state = {};
        }
    }

    setState(state) {
        this.state = JSON.parse(JSON.stringify(state));
        const saveState = JSON.parse(JSON.stringify(state));
        if (saveState.loginErrors) {
            delete saveState.loginErrors;
        }
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify(saveState));
        this.emitChange();
    }

    setUser(user) {
        let state = this.getState();
        if (!state) {
            state = {};
        }
        state.user = user;
        this.setState(state);
    }

    patchUser(user) {
        const newState = this.getState();
        if (user.lastAcceptedEulaVersion) {
            set(
                newState,
                'user.lastAcceptedEulaVersion',
                user.lastAcceptedEulaVersion,
            );
        }
        this.setState(newState);
    }

    getUser() {
        return get(this.getState(), 'user', null);
    }

    setLoginErrors(errors) {
        let state = this.getState();
        if (!state) {
            state = {};
        }
        state.loginErrors = errors;
        this.setState(state);
    }

    getLoginErrors() {
        return get(this.getState(), 'loginErrors', null);
    }

    setActiveFacility(facility) {
        let state = this.getState();
        if (!state) {
            state = {};
        }
        state.activeFacility = facility;
        this.setState(state);
    }

    getActiveFacility() {
        return get(this.getState(), 'activeFacility', null);
    }

    setAuth(auth) {
        let state = this.getState();
        if (!state) {
            state = {};
        }
        state.auth = auth;
        this.setState(state);
    }

    getAuth() {
        return get(this.getState(), 'auth');
    }

    getAuthToken() {
        return get(this.getState(), 'auth.authToken');
    }

    isAuthTokenValid() {
        const expirationDate = get(this.getState(), 'auth.expirationDate');
        return expirationDate > Date.now();
    }

    isAuthenticated() {
        const token = this.getAuthToken();
        const isAuthTokenValid = this.isAuthTokenValid();

        return !isNil(token) && !isAuthTokenValid;
    }

    hasPermission(permission) {
        const roles = get(this.state, 'user.roles');
        if (isNil(roles)) {
            return false;
        }

        let isMatch = false;
        map(roles, value => {
            const permissions = value;
            for (let i = 0; i < permissions.length; i += 1) {
                if (permissions[i] === permission) {
                    isMatch = true;
                }
            }
        });

        return isMatch;
    }

    isAdmin() {
        return this.hasPermission('ADMINISTRATOR');
    }

    isPortalAdmin() {
        if (this.hasPermission('tenant:create')) return true;
        if (this.hasPermission('tenant:delete')) return true;

        return false;
    }

    requiresEulaAgreement() {
        const lastAcceptedEulaVersion = get(
            this.state,
            'user.lastAcceptedEulaVersion',
            0,
        );

        return lastAcceptedEulaVersion < 1;
    }

    login({ user, auth }) {
        this.setState({ user, auth });
    }
}

UserStore.storeName = 'UserStore';
UserStore.handlers = {
    SET_USER: 'setUser',
    SET_AUTH: 'setAuth',
    SET_LOGIN_ERRORS: 'setLoginErrors',
    LOGIN: 'login',
    PATCH_USER: 'patchUser',
    SET_ACTIVE_FACILITY: 'setActiveFacility',
    LOGOUT: 'resetState',
};

export default UserStore;
