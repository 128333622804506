import PropTypes from 'prop-types';

export const patientShape = PropTypes.shape({
    patient: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        middleName: PropTypes.string,
        suffix: PropTypes.string,
        gender: PropTypes.string,
        dob: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        homePhone: PropTypes.string,
        cellPhone: PropTypes.string,
        workPhone: PropTypes.string,
        ssn: PropTypes.string,
        source: PropTypes.string,
    }),
    facility: PropTypes.string,
    selected: PropTypes.bool,
    status: PropTypes.string.isRequired,
}).isRequired;

export const patientResultsPropType = PropTypes.shape({
    patients: PropTypes.arrayOf(patientShape),
}).isRequired;
