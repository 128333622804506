import FACILITIES from './data/facilities';

export default function loadFacilities({ request, resolve, reject }) {
    const parts = request.url.split('/');
    const facilities = FACILITIES[`${parts[parts.length - 1]}`];

    if (facilities) {
        resolve(facilities);
    } else {
        reject(404);
    }
}
