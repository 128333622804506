import get from 'lodash/get';

import Pendo from '@audacious/fluxible-pendo';
import SocketPlugin from '@audacious/fluxible-socketio';
import { Client } from '@audacious/client';

import './styles.scss';

import stores from './stores';
import { createServiceConfiguration } from './services';
import { createApplicationContext, createContextConfiguration } from './config';
import Application from './components/application';

// Force Pipeline
export default config => {
    const app = new Client({
        components: {
            Application,
        },
        stores,
        context: createApplicationContext(get(config, 'context', {})),
        services: createServiceConfiguration(get(config, 'services', {})),
    });

    app.plug(Pendo);
    app.plug(SocketPlugin);

    function createContext(contextConfiguration) {
        return app.createContext(
            createContextConfiguration(contextConfiguration),
        );
    }

    return {
        createContext,
        createDispatcherInstance: app.createDispatcherInstance.bind(app),
        plug: app.plug.bind(app),
        getPlugin: app.getPlugin.bind(app),
        getComponent: app.getComponent.bind(app),
        registerStore: app.registerStore.bind(app),
        dehydrate: app.dehydrate.bind(app),
        rehydrate: app.rehydrate.bind(app),
    };
};
