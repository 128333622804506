import get from 'lodash/get';

export default config => ({
    serviceName: 'searchHistory.retrieveRdResults',
    authenticate: true,
    config: {
        method: 'GET',
        url: get(config, 'endpoints.pulse.searchHistory.retrieveRdResults'),
    },
});
