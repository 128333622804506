import get from 'lodash/get';
import forEach from 'lodash/forEach';
import dateFormatter from '@audacious/web-common/formatters/dateFormatter';
import printHtml from './print-html';

const styles = `
body {
    font-family: sans-serif;
}

.patient-info {
    display: flex;
}

.column {
    margin-right: 100px;
}

.header {
    border-bottom: 1px solid black;
    padding-bottom: 8px;
}

.header .item {
    display: flex;
    align-items: flex-end;
}

.header .item .label {
    margin-right: 16px;
    font-weight: bold;
    width: 125px;
}

.footer {
    margin-top: 8px;
    border: none;
}

.medication {
    border-bottom: 1px solid black;
    padding: 8px 0;
}

.medication .label {
    margin-right: 16px;
}

.medication .section {
    display: flex;
}

.medication .section .name-value {
    font-weight: bold;
}

.medication .misc-items {
    display: flex;
    justify-content: space-evenly;
    margin: 8px 0;
}

.medication .misc-item .label {
    font-weight: bold;
}

.medication .pharmacy {
    margin-top: 8px;
}

`;

const title = 'Medications';

function getMedicationItem(medication) {
    const name = get(medication, 'name');
    const sigText = get(medication, 'sig[0].SigText', '');
    const fillDate = get(medication, 'fillDate', '-');
    const writtenDate = get(medication, 'writtenDate', '-');
    const quantity = get(medication, 'quantity', '-');
    const days = get(medication, 'days', '-');
    const refills = get(medication, 'refills', '-');
    const prescriberName = get(medication, 'prescriberName', '-');
    const prescriberBusinessName = get(
        medication,
        'prescriberBusinessName',
        '',
    );
    const prescriberAddress = get(medication, 'prescriberAddress', '');
    const prescriberPhone = get(medication, 'prescriberPhone', '');
    const pharmacyName = get(medication, 'pharmacyName', '');
    const pharmacyAddress = get(medication, 'pharmacyAddress', '');
    const pharmacyPhone = get(medication, 'pharmacyPhone', '');

    return `
    <div class="medication">
        <div class="section">
            <div class="label">
                Medication:
            </div>
            <div class="value">
                <div class="name-value">
                    ${name}
                </div>
                <div class="sig-text">
                    ${sigText}
                </div>
            </div>
        </div>
        <div class="misc-items">
            <div class="misc-item">
                <div class="label">
                    Fill Date:
                </div>
                <div class="value">
                    ${fillDate}
                </div>
            </div>
            <div class="misc-item">
                <div class="label">
                    Written Date:
                </div>
                <div class="value">
                    ${writtenDate}
                </div>
            </div>
            <div class="misc-item">
                <div class="label">
                    Quantity:
                </div>
                <div class="value">
                    ${quantity}
                </div>
            </div>
            <div class="misc-item">
                <div class="label">
                    Days:
                </div>
                <div class="value">
                    ${days}
                </div>
            </div>
            <div class="misc-item">
                <div class="label">
                    Refills:
                </div>
                <div class="value">
                    ${refills}
                </div>
            </div>
        </div>
        <div class="section">
            <div class="label">
                Prescriber:
            </div>
            <div class="value">
                <div class="name-value">
                    ${prescriberName}
                </div>
                <div>
                    ${prescriberBusinessName}
                </div>
                <div>
                    ${prescriberAddress}
                </div>
                <div>
                    Tel: ${prescriberPhone}
                </div>
            </div>
        </div>
        <div class="section pharmacy">
            <div class="label">
                Pharmacy:
            </div>
            <div class="value">
                <div class="name-value">
                    ${pharmacyName}
                </div>
                <div>
                    ${pharmacyAddress}
                </div>
                <div>
                    Tel: ${pharmacyPhone}
                </div>
            </div>
        </div>
    </div>
    `;
}

function printMedications({
    medications,
    userName,
    patientFields,
    receivedDateTime,
}) {
    let html = `
    <div class="patient-info header">
        <div class="column">
            <div class="item">
                <div class="label">
                    First Name:
                </div>
                <div class="value">
                    ${get(patientFields, 'firstName', '')}
                </div>
            </div>
            <div class="item">
                <div class="label">
                    Last Name:
                </div>
                <div class="value">
                    ${get(patientFields, 'lastName', '')}
                </div>
            </div>
            <div class="item">
                <div class="label">
                    Date of Birth:
                </div>
                <div class="value">
                    ${get(patientFields, 'dob', '')}
                </div>
            </div>
            <div class="item">
                <div class="label">
                    Gender:
                </div>
                <div class="value">
                    ${get(patientFields, 'gender', '')}
                </div>
            </div>
            <div class="item">
                <div class="label">
                    Address:
                </div>
                <div class="value">
                    ${get(patientFields, 'address1', '')}
                </div>
            </div>
            <div class="item">
                <div class="label">
                    City:
                </div>
                <div class="value">
                    ${get(patientFields, 'city', '')}
                </div>
            </div>
            <div class="item">
                <div class="label">
                    State:
                </div>
                <div class="value">
                    ${get(patientFields, 'state', '') || ''}
                </div>
            </div>
            <div class="item">
                <div class="label">
                    Zip Code:
                </div>
                <div class="value">
                    ${get(patientFields, 'zip', '')}
                </div>
            </div>
            <div class="item">
                <div class="label">
                    Phone:
                </div>
                <div class="value">
                    ${get(patientFields, 'homePhone', '')}
                </div>
            </div>
        </div>
        <div class="column">
            <div class="item">
                <div class="label">
                    Data from Surescripts received on:
                </div>
                <div class="value">
                    ${receivedDateTime}
                </div>
            </div>
        </div>
    </div>
    `;

    forEach(medications, medication => {
        html += getMedicationItem(medication);
    });

    const date = dateFormatter(new Date().toString(), {
        outFormat: 'MM/DD/YYYY h:mm:ss a',
    });

    html += `
    <div class="footer header">
        <div class="item">
            <div class="label">
                User Name:
            </div>
            <div class="value">
                ${userName}
            </div>
        </div>
        <div class="item">
            <div class="label">
                Date:
            </div>
            <div class="value">
                ${date}
            </div>
        </div>
    </div>
    `;

    printHtml({ html, title, styles });
}

export default printMedications;
