import { v4 as uuid4 } from 'uuid';
import isNil from 'lodash/isNil';

import QueryType from './query-type';
import log from '../log';

/**
 * Setup socket connetion.
 */
export async function setup(context, sessionKey, facilityId) {
    context.socket.connect({
        connectionOptions: {
            withCredentials: true,
            query: { sessionKey },
            extraHeaders: {
                'x-facility-id': facilityId,
            },
        },
        forceNewConnection: true,
    });
}

export function finishQueryIfComplete(context, queryId) {
    const query = context.getStore('QueryStore').getQuery(queryId);

    if (!query) {
        return;
    }

    if (query.resultRequests.length + 1 >= query.numExpectedResults) {
        context.socket.removeHandlers({ event: queryId });
    }
}

/**
 * Issues patient discovery, query document and retrieve document requests.
 * These are long running requests that involve sending the
 * query in an HTTP request and then listening on a socket for a response.
 * Once a response is received it issues an HTTP request to retrieve the document.
 */
export function issueQuery(
    context,
    queryType,
    buildRequestData,
    associatedIds,
    requestId = null,
) {
    const session = context.getStore('Session');
    const socketStore = context.getStore('SocketStore');
    const queryId = requestId || uuid4();
    const serviceBasePath =
        queryType === QueryType.MEDICATIONS ? 'surescripts' : 'pulse';

    // need to store the original pd request id to add to future calls
    // so search history can work
    if (queryType === QueryType.PATIENT_DISCOVERY) {
        context.dispatch('SET_PD_REQUEST_ID', queryId);
    }

    const originalPdRequestId = context
        .getStore('QueryStore')
        .getOriginalPdRequestId();

    const user = {
        firstName: session.getUser().getFirstName(),
        lastName: session.getUser().getLastName(),
        tenantId: session.getTenant().getId(),
    };

    const requestData = buildRequestData({
        queryId,
        user,
        facilityId: session.getFacility().getId(),
        facilityName: session.getFacility().getName(),
        facilityZipCode: session.getFacility().getZipCode(),
        originalPdRequestId,
    });
    if (!socketStore.isConnected()) {
        context.dispatch('ADD_QUERY', {
            id: queryId,
            type: queryType,
            socketDisconnected: true,
        });
        context.notify.error({
            message: `A network error has occurred`,
        });
        if (queryType === QueryType.MEDICATIONS) {
            context.dispatch('ADD_MEDICATIONS_ERRORS', {});
        }

        return queryId;
    }

    // Listen for this query's events sent over the socket connection
    // that let the UI know a result has been retrieved.
    context.socket.addHandler({
        event: queryId,
        handler: (ctx, msg) => {
            log('log', msg);

            const parsedMsg = JSON.parse(msg);
            let { requestId: childRequestId } = parsedMsg;
            const {
                numResults,
                surescriptsResponseDate,
                errorCode,
                errorDescription,
            } = parsedMsg;

            context.dispatch('ADD_MEDICATIONS_TIME', {
                surescriptsResponseDate,
            });

            if (errorCode || errorDescription) {
                // If Surescripts gave us back an error of some sort
                finishQueryIfComplete(context, queryId);

                context.dispatch('ADD_MEDICATIONS_ERRORS', {
                    errorCode,
                    errorDescription,
                });

                context.dispatch('ADD_QUERY_RESULT', {
                    id: queryId,
                    resultRequest: {
                        serviceId: queryId,
                        results: [],
                    },
                    requestError: true,
                });
            } else if (isNil(numResults) || numResults) {
                // Retrieve the corresponding result when an event is received.
                if (queryType === QueryType.RETRIEVE_DOCUMENT) {
                    childRequestId = `${childRequestId}/download`;
                    // TODO: Add the ability to specify xsl for a given tenant
                }
                context.service.query.retrieveResult({
                    ref: `query.retrieveResult-${queryId}-${childRequestId}`,
                    options: {
                        serviceBasePath,
                        queryType,
                        queryId,
                        childRequestId,
                        associatedIds,
                    },
                });
            } else {
                // there are no results, finish the result instead of making a service call
                finishQueryIfComplete(context, queryId);

                context.dispatch('ADD_QUERY_RESULT', {
                    id: queryId,
                    resultRequest: {
                        serviceId: queryId,
                        results: [],
                    },
                    requestError: false,
                });
            }
        },
        cb: () => {
            context.socket.emit({
                event: 'subscribeRequestId',
                payload: queryId,
            });
        },
    });

    context.dispatch('ADD_QUERY', {
        id: queryId,
        type: queryType,
    });

    // Make a request that starts the query
    context.service.query.create({
        ref: `query.create-${queryId}`,
        data: requestData,
        options: { serviceBasePath, queryType, queryId, associatedIds },
    });

    return queryId;
}

/**
 * Cancel all queries by removing associated event handlers and data
 * in stores.
 */
export function clear(context) {
    context.socket.removeHandlers();
    context.dispatch('REMOVE_QUERIES');
    context.dispatch('CLEAR_PATIENT_RESULTS');
    context.dispatch('CLEAR_DOCUMENT_RESULTS');
    context.dispatch('SET_PD_REQUEST_ID', '');
    context.dispatch('CLEAR_VIEW_DOCUMENTS');
}
