import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Button from '@audacious/components/components/Button';
import { Row, Column } from '@audacious/components/components/Grid';
import {
    PageContainer,
    PageContainerGroup,
    PageTitle,
} from '@audacious/components/components/Page';
import { faMagnifyingGlass } from '@audacious/icons/regular/faMagnifyingGlass';
import { faArrowLeft } from '@audacious/icons/regular/faArrowLeft';
import { withRouter } from 'react-router-dom';
import TextInput from '@audacious/components/components/TextInput';
import { Text, SubHeading } from '@audacious/components/components/Typography';
import TableMessage from '@audacious/components/components/TableMessage';
import Spinner from '@audacious/components/components/Spinner';
import { searchHistoryItemPropType } from '../../../prop-types/search-history-item';
import { localizationShape } from '../../../prop-types/localization';
import SearchHistoryTable from './search-history-table/search-history-table';
import { clearSearchHistory } from '../../../../actions/search-history-actions';
import searchFilter from '../../../../common/util/search-filter';

import { SEARCH } from '../../routes';

import './search-history-content.scss';

const START_FILTER_LENGTH = 2;

class SearchHistoryContent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
        };

        this.handleItemSelection = this.handleItemSelection.bind(this);
    }

    componentDidMount() {
        const {
            tenantId,
            facilityId,
            facilityName,
            fluxibleContext: {
                executeAction,
                service: { searchHistory },
            },
        } = this.props;

        const params = {
            facilityName,
        };

        executeAction(clearSearchHistory);

        // fetch search history
        const options = { tenantId, facilityId };
        searchHistory.retrievePatientRequests({ options, params });
    }

    handleItemSelection(item) {
        const { history } = this.props;

        const { parentRequestId } = item;
        history.push(`${SEARCH}/${parentRequestId}`);
    }

    render() {
        const {
            history,
            searchHistory,
            localization,
            isRequestingSearchHistory,
            patientRequestId,
        } = this.props;
        const { filter } = this.state;

        let filteredSearchHistory = [];
        if (filter && filter.length >= START_FILTER_LENGTH) {
            filteredSearchHistory = searchFilter(searchHistory, filter, [
                { path: 'formattedPatient.fullName' },
                { path: 'formattedPatient.dob', isDate: true },
                { path: 'formattedPatient.gender' },
                { path: 'formattedPatient.address' },
                { path: 'formattedPatient.homePhone' },
                { path: 'formattedPatient.searchDate' },
            ]);
        }

        let messageRender = null;

        if (isRequestingSearchHistory) {
            messageRender = (
                <div className="message-container">
                    <Spinner
                        id="search-history-spinner"
                        variant="inline"
                        spaceFromTop="sm"
                    />
                </div>
            );
        } else if (!filter || filter.length < START_FILTER_LENGTH) {
            messageRender = (
                <TableMessage header="Please enter patient information in the search bar to retrieve results!" />
            );
        } else if (filteredSearchHistory.length === 0) {
            messageRender = <TableMessage header="No data available!" />;
        }

        return (
            <>
                <PageTitle pageName="Patient Search" />
                <PageContainer
                    className="search-history-page-container"
                    asCard
                    allowScroll
                >
                    <PageContainerGroup>
                        <Row gutter="24">
                            <Column width="fill">
                                <SubHeading
                                    level="4"
                                    id="search-history-heading"
                                >
                                    Search History
                                </SubHeading>
                            </Column>
                            <Column width="content">
                                <Button
                                    id="searchHistoryBack"
                                    className="patient-search-btn"
                                    color="primary"
                                    variant="opaque"
                                    leftIcon={faArrowLeft}
                                    onClick={() => {
                                        if (patientRequestId) {
                                            history.push(
                                                `${SEARCH}/${patientRequestId}`,
                                            );
                                        } else {
                                            history.push(SEARCH);
                                        }
                                    }}
                                >
                                    Back to Search
                                </Button>
                            </Column>
                        </Row>
                        <Row gutter="24">
                            <Column width="fill">
                                <Text size="lg" weight="semi-bold">
                                    Enter patient information
                                </Text>
                            </Column>
                        </Row>
                        <Row gutter="8" enableAfter>
                            <Column width={['12', '6', '4', '3']}>
                                <TextInput
                                    id="search-history-filter"
                                    className="width-sm notification-search"
                                    placeholder="Search patient"
                                    leftIcon={{
                                        icon: faMagnifyingGlass,
                                    }}
                                    value={filter}
                                    onChange={v => this.setState({ filter: v })}
                                    size="md"
                                />
                            </Column>
                        </Row>
                    </PageContainerGroup>
                    <SearchHistoryTable
                        localization={localization.table}
                        items={filteredSearchHistory}
                        onItemSelection={this.handleItemSelection}
                    />
                    <PageContainerGroup>{messageRender}</PageContainerGroup>
                </PageContainer>
            </>
        );
    }
}

SearchHistoryContent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    tenantId: PropTypes.string.isRequired,
    facilityId: PropTypes.string.isRequired,
    facilityName: PropTypes.string.isRequired,
    searchHistory: PropTypes.arrayOf(searchHistoryItemPropType).isRequired,
    localization: PropTypes.shape(localizationShape.searchHistory),
    isRequestingSearchHistory: PropTypes.bool.isRequired,
    patientRequestId: PropTypes.string.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func.isRequired,
        service: PropTypes.shape({
            searchHistory: PropTypes.shape({
                retrievePatientRequests: PropTypes.func.isRequired,
            }),
        }).isRequired,
    }).isRequired,
};

SearchHistoryContent.defaultProps = {
    history: null,
    localization: null,
};

// eslint-disable-next-line no-class-assign
SearchHistoryContent = withRouter(SearchHistoryContent);

export default connectToStores(
    applyFluxibleContext(SearchHistoryContent),
    [
        'Session',
        'SearchHistoryStore',
        'LocalizationStore',
        'ServiceStore',
        'QueryStore',
    ],
    context => {
        const sessionStore = context.getStore('Session');

        const tenantId = sessionStore.getTenant().getId();
        const facilityId = sessionStore.getFacility().getId();
        const facilityName = sessionStore.getFacility().getName();

        const searchHistoryStore = context.getStore('SearchHistoryStore');
        const searchHistory = searchHistoryStore.getPatientRequests();

        const localizationStore = context.getStore('LocalizationStore');
        const localization = localizationStore.getState().searchHistory;

        const serviceStore = context.getStore('ServiceStore');
        const isRequestingSearchHistory = serviceStore.isRequesting(
            'searchHistory.retrievePatientRequests',
        );

        const queryStore = context.getStore('QueryStore');

        return {
            tenantId,
            facilityId,
            facilityName,
            searchHistory,
            localization,
            isRequestingSearchHistory,
            patientRequestId: queryStore.getOriginalPdRequestId(),
        };
    },
);
