import brand from '../assets/images/logo-lg.png';
import header from '../assets/images/logo.png';
import footer from '../assets/images/logo-onc.png';

export default {
    brand: {
        url: brand,
        alt: 'PULSE',
    },
    header: {
        url: header,
        alt: 'PULSE',
    },
    footer: {
        url: footer,
        alt: 'ONC',
    },
};
