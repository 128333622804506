import get from 'lodash/get';

export default config => ({
    serviceName: 'searchHistory.retrieveRdRequests',
    authenticate: true,
    config: {
        method: 'GET',
        url: get(config, 'endpoints.pulse.searchHistory.retrieveRdRequests'),
    },
});
