import isNil from 'lodash/isNil';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import toInteger from 'lodash/toInteger';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import formatName from '@audacious/web-common/formatters/nameFormatter';
import formatAddress from '@audacious/web-common/formatters/addressFormatter';
import formatDate from '../common/util/format-date';

const initialState = {
    medications: [],
    receivedDateTime: null,
    isError: false,
    errorCode: null,
    errorDescription: null,
};

function formatNullableDate(value, dateFormat) {
    if (isNil(value) || value.length <= 0) {
        return '';
    }

    // All dates from SureScripts come in ISO8601 format
    return formatDate(value, dateFormat, 'YYYY-MM-DDTHH:mm:ssZ');
}

function getPrescriberFields(prescriber) {
    let prescriberName = '-';
    let prescriberPhone = '';
    let prescriberAddress = '';
    let prescriberBusinessName = '';

    if (prescriber) {
        const firstName = get(prescriber, '0.Item.Name.FirstName', '');
        const middleName = get(prescriber, '0.Item.Name.MiddleName', '');
        const lastName = get(prescriber, '0.Item.Name.LastName', '');

        prescriberName = formatName('fml', {
            firstName,
            middleName,
            lastName,
        });

        prescriberPhone = get(
            prescriber,
            '0.Item.CommunicationNumbers.PrimaryTelephone.Number',
            '',
        );

        const addressObject = get(prescriber, '0.Item.Address');

        const address1 = get(addressObject, 'AddressLine1', '');
        const address2 = get(addressObject, 'AddressLine2', '');
        const streets = [address1, address2];
        const city = get(addressObject, 'City', '');
        const state = get(addressObject, 'StateProvince', '');
        const postalCode = get(addressObject, 'PostalCode', '');

        prescriberAddress = formatAddress({
            streets,
            city,
            state,
            postalCode,
        });

        prescriberBusinessName = get(
            prescriber,
            '0.Item.PracticeLocation.BusinessName',
            '',
        );
    }

    return {
        prescriberName,
        prescriberPhone,
        prescriberAddress,
        prescriberBusinessName,
    };
}

function getPharmacyFields(pharmacy) {
    let pharmacyName = '-';
    let pharmacyPhone = '';
    let pharmacyAddress = '';

    if (pharmacy) {
        pharmacyName = get(pharmacy, '0.BusinessName', '-');
        pharmacyPhone = get(
            pharmacy,
            '0.CommunicationNumbers.PrimaryTelephone.Number',
            '',
        );

        const addressObject = get(pharmacy, '0.Address');

        const address1 = get(addressObject, 'AddressLine1', '');
        const address2 = get(addressObject, 'AddressLine2', '');
        const streets = [address1, address2];
        const city = get(addressObject, 'City', '');
        const state = get(addressObject, 'StateProvince', '');
        const postalCode = get(addressObject, 'PostalCode', '');

        pharmacyAddress = formatAddress({
            streets,
            city,
            state,
            postalCode,
        });
    }

    return {
        pharmacyName,
        pharmacyPhone,
        pharmacyAddress,
    };
}

class MedicationsResultsStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);
    }

    // Use callback in setState when referencing the previous state
    addResults(payload) {
        if (isNil(payload) || isNil(payload.results)) {
            return;
        }

        const { medications } = this.state;

        const results = get(payload, 'results');

        let allFormattedResults = [];

        for (let i = 0; i < results.length; i += 1) {
            const result = results[i];
            const data = get(result, 'Body.Item');

            const formatted = reduce(
                data.Items,
                (acc, medication) => {
                    const prescriber = get(medication, 'Prescriber', null);
                    const pharmacy = get(medication, 'Pharmacy', null);

                    const {
                        prescriberName,
                        prescriberBusinessName,
                        prescriberAddress,
                        prescriberPhone,
                    } = getPrescriberFields(prescriber);

                    const {
                        pharmacyName,
                        pharmacyAddress,
                        pharmacyPhone,
                    } = getPharmacyFields(pharmacy);

                    acc.push({
                        name: get(medication, 'DrugDescription', null),
                        sig: get(medication, 'Sig', null),
                        fillDate: formatNullableDate(
                            get(medication, 'LastFillDate.0.Item', null),
                        ),
                        writtenDate: formatNullableDate(
                            get(medication, 'WrittenDate.0.Item', null),
                        ),
                        quantity: isNil(get(medication, 'Quantity.0.Value'))
                            ? '-'
                            : toInteger(get(medication, 'Quantity.0.Value')),
                        quantitySort: isNil(get(medication, 'Quantity.0.Value'))
                            ? -1
                            : toInteger(get(medication, 'Quantity.0.Value')),
                        days: isNil(get(medication, 'DaysSupply.0'))
                            ? '-'
                            : toInteger(get(medication, 'DaysSupply.0')),
                        daysSort: isNil(get(medication, 'DaysSupply.0'))
                            ? -1
                            : toInteger(get(medication, 'DaysSupply.0')),
                        refills: isNil(get(medication, 'RefillsRemaining.0'))
                            ? '-'
                            : toInteger(get(medication, 'RefillsRemaining.0')),
                        refillsSort: isNil(
                            get(medication, 'RefillsRemaining.0'),
                        )
                            ? -1
                            : toInteger(get(medication, 'RefillsRemaining.0')),
                        prescriber,
                        prescriberName,
                        prescriberPhone,
                        prescriberAddress,
                        prescriberBusinessName,
                        pharmacyName,
                        pharmacyPhone,
                        pharmacyAddress,
                        pharmacy,
                        id:
                            get(medication, 'DrugCoded.0.ProductCode.Code') ||
                            get(medication, 'DrugCoded.0.DrugDBCode.Code'),
                    });

                    return acc;
                },
                [],
            );

            allFormattedResults = allFormattedResults.concat(formatted);
        }

        const { receivedDateTime } = this.state;

        this.setState({
            medications: medications.concat(allFormattedResults),
            receivedDateTime,
            isError: false,
        });
    }

    getMedications() {
        return this.state.medications;
    }

    getReceivedDateTime() {
        return this.state.receivedDateTime;
    }

    addMedicationsErrors({ errorCode, errorDescription }) {
        this.setState({
            errorCode,
            errorDescription,
            isError: true,
            medications: [],
        });
    }

    addMedicationsTime(time) {
        if (time.surescriptsResponseDate) {
            const { medications } = this.state;
            this.setState({
                receivedDateTime: formatNullableDate(
                    time.surescriptsResponseDate,
                    'YYYY-MM-DD HH:mm',
                ),
                medications,
                isError: false,
            });
        }
    }

    isError() {
        return this.state.isError;
    }

    getErrorCode() {
        return this.state.errorCode;
    }

    getErrorDescription() {
        return this.state.errorDescription;
    }
}

MedicationsResultsStore.storeName = 'MedicationsResultsStore';
MedicationsResultsStore.handlers = {
    ADD_MEDICATIONS_RESULTS: 'addResults',
    CLEAR_PATIENT_RESULTS: 'resetState',
    ADD_MEDICATIONS_TIME: 'addMedicationsTime',
    ADD_MEDICATIONS_ERRORS: 'addMedicationsErrors',
    LOGOUT: 'resetState',
};

export default MedicationsResultsStore;
