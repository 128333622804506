import moment from 'moment';

export default function formatDate(
    dateString = '',
    outDateFormat = 'MM/DD/YYYY',
    inDateFormat = 'YYYYMMDDHHmmss',
) {
    let date = moment(dateString, inDateFormat);
    date = date.isValid() ? date : moment(dateString);

    return date.isValid() ? date.format(outDateFormat) : '';
}
