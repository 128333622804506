import get from 'lodash/get';

function beforeRequestEpraPreferences(context) {
    context.dispatch('RETRIEVE_EPRA_PREFERENCES_START');
}

function retrieveEpraPrefsSuccess(context, { response }) {
    context.dispatch(
        'RETRIEVE_EPRA_PREFERENCES_FINISH',
        response ? response.data : {},
    );
}

function retrieveEpraPrefsFailure(context, { response }) {
    context.dispatch(
        'RETRIEVE_EPRA_PREFERENCES_FAILED',
        response ? response.data : {},
    );
}

export default config => ({
    serviceName: 'epraPreferences.retrieveEpraPreferences',
    authenticate: true,
    config: {
        method: 'GET',
        url: get(
            config,
            'endpoints.pulse.epraPreferences.retrieveEpraPreferences',
        ),
    },
    beforeRequest: beforeRequestEpraPreferences,
    onSuccess: retrieveEpraPrefsSuccess,
    onError: retrieveEpraPrefsFailure,
    onFailed: retrieveEpraPrefsFailure,
    onTimeout: retrieveEpraPrefsFailure,
    onFatal: retrieveEpraPrefsFailure,
});
