/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-did-update-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { PageTitle } from '@audacious/components/components/Page';
import Spinner from '@audacious/components/components/Spinner';
import PatientDemoForm from './patient-demo-form/patient-demo-form';
import PatientDataView from './patient-data-view/patient-data-view';
import DocumentView from './document-view/document-view';
import { loadSearchHistory } from '../../../../actions/search-history-actions';
import { setupSearchPage } from '../../../../actions/patient-discovery-actions';
import { SEARCH } from '../../routes';
import QueryType from '../../../../common/query/query-type';

import './search-content.scss';

class SearchContent extends PureComponent {
    componentDidMount() {
        const {
            match,
            tenantId,
            fluxibleContext: {
                executeAction,
                service: { epraPreferences },
            },
        } = this.props;

        epraPreferences.retrieveEpraPreferences({
            options: {
                tenantId,
            },
        });

        executeAction(setupSearchPage);

        const searchId = get(match, 'params.searchId', '');

        this.fetchSearchHistory(searchId);
    }

    componentDidUpdate(prevProps) {
        const { patientRequestId: prevId, match: prevMatch } = prevProps;
        const { patientRequestId, history, match } = this.props;

        const prevSearchId = get(prevMatch, 'params.searchId', '');
        const searchId = get(match, 'params.searchId', '');

        // a patient search was just begun. Change the url to the correct one
        if (
            patientRequestId &&
            patientRequestId !== prevId &&
            patientRequestId !== searchId // avoid pushing the url to the browser history twice
        ) {
            history.push(`${SEARCH}/${patientRequestId}`);
        }

        // We have navigated to a new url containing a searchId.
        // Most likely from the user pressing back or forward in their browser.
        // If it does not equal the patientRequestId we need to fetch search history
        if (
            searchId &&
            searchId !== prevSearchId &&
            searchId !== patientRequestId
        ) {
            this.fetchSearchHistory(searchId);
        }
    }

    fetchSearchHistory(searchId) {
        const {
            facilityId,
            facilityName,
            tenantId,
            history,
            fluxibleContext: { executeAction },
        } = this.props;

        const options = {
            parentRequestId: searchId,
            facilityId,
            tenantId,
        };

        const params = {
            facilityName,
        };

        if (searchId) {
            executeAction(loadSearchHistory, { options, params, history });
        }
    }

    render() {
        const {
            match,
            historyIsLoading,
            patientQueryExists,
            searchHistoryEnabled,
        } = this.props;

        const searchId = get(match, 'params.searchId', '');
        let patientDataView = null;

        if (patientQueryExists) {
            // As long the user has made an active query then go ahead and show the patient data view.
            patientDataView = <PatientDataView />;
        }

        return historyIsLoading ? (
            <div className="sc-loading-container">
                <Spinner size="lg" variant="overlay" spaceFromTop="md" />
            </div>
        ) : (
            <>
                <PageTitle pageName="Patient Search" />
                <PatientDemoForm
                    searchId={searchId}
                    searchHistoryEnabled={searchHistoryEnabled}
                />
                {patientDataView}
                <DocumentView />
            </>
        );
    }
}

SearchContent.propTypes = {
    match: PropTypes.shape({ params: PropTypes.objectOf(PropTypes.string) })
        .isRequired,
    tenantId: PropTypes.string.isRequired,
    facilityId: PropTypes.string.isRequired,
    facilityName: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
    patientRequestId: PropTypes.string.isRequired,
    historyIsLoading: PropTypes.bool.isRequired,
    patientQueryExists: PropTypes.bool.isRequired,
    searchHistoryEnabled: PropTypes.bool.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func.isRequired,
        service: PropTypes.shape({
            epraPreferences: PropTypes.shape({
                retrieveEpraPreferences: PropTypes.func.isRequired,
            }),
        }).isRequired,
    }).isRequired,
};

SearchContent.defaultProps = {
    history: null,
};

// eslint-disable-next-line no-class-assign
SearchContent = withRouter(SearchContent);

export default connectToStores(
    applyFluxibleContext(SearchContent),
    [
        'Session',
        'SearchHistoryStore',
        'QueryStore',
        'PatientResultsStore',
        'ApplicationContext',
    ],
    context => {
        const sessionStore = context.getStore('Session');

        const tenantId = sessionStore.getTenant().getId();
        const facilityId = sessionStore.getFacility().getId();
        const facilityName = sessionStore.getFacility().getName();

        const searchHistoryStore = context.getStore('SearchHistoryStore');
        const historyIsLoading = searchHistoryStore.getIsLoading();

        const queryStore = context.getStore('QueryStore');

        const applicationContextStore = context.getStore('ApplicationContext');
        const searchHistoryEnabled = !applicationContextStore.config(
            'disableSearchHistory',
        );

        return {
            tenantId,
            facilityId,
            facilityName,
            patientRequestId: queryStore.getOriginalPdRequestId(),
            historyIsLoading,
            patientQueryExists: queryStore.doesQueryOfTypeExist(
                QueryType.PATIENT_DISCOVERY,
            ),
            searchHistoryEnabled,
        };
    },
);
