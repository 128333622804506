import React from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import toUpper from 'lodash/toUpper';
import forEach from 'lodash/forEach';
import Spinner from '@audacious/components/components/Spinner';
import Button from '@audacious/components/components/Button';
import { PageContainerGroup } from '@audacious/components/components/Page';
import { Text } from '@audacious/components/components/Typography';
import { faXmark } from '@audacious/icons/solid/faXmark';
import PatientDemoResultTable from './patient-demo-result-table';
import QueryType from '../../../../../common/query/query-type';
import { localizationShape } from '../../../../prop-types/localization';
import {
    selectPatient,
    deselectPatient,
    selectAllPatients,
    deselectAllPatients,
} from '../../../../../actions/patient-discovery-actions';
import { patientShape } from '../../../../prop-types/patient-results';

import './patient-demo-results.scss';

class PatientDemoResults extends React.Component {
    constructor(props) {
        super(props);

        this.handlePatientCheck = this.handlePatientCheck.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
    }

    handlePatientCheck(patient, checked) {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(checked ? selectPatient : deselectPatient, patient);
    }

    handleClearClick() {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(deselectAllPatients);
    }

    handleSelectAll(selected) {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        if (selected) {
            executeAction(selectAllPatients);
        } else {
            executeAction(deselectAllPatients);
        }
    }

    render() {
        const {
            patients,
            loading,
            delayed,
            timedOut,
            localization,
            isFinishedSearch,
            searchHasBegun,
            selectedPatientIds,
            isError,
            errorField,
        } = this.props;

        const retrievingSpinner = loading ? (
            <div id="patient-results-spinner" className="retrieving f6">
                <Spinner size="xs" />
                {!delayed
                    ? ' Retrieving results...'
                    : ' Patient search still in process.'}
            </div>
        ) : null;

        let noResultsMessage = null;
        const patientsExist = patients.length > 0;

        if (!loading && !patientsExist) {
            let text = 'Initiate a search to begin';

            if (isFinishedSearch || searchHasBegun) {
                text = 'No records were found for this patient.';
            }
            if (isError) {
                text =
                    "We're unable to retrieve records at this time. Please try again.";

                if (toUpper(errorField) === 'FACILITYZIPCODE') {
                    text =
                        'We’re unable to retrieve records at this time because the Facility Zip Code is invalid. Please contact your System Administrator for assistance.';
                }
            }
            if (timedOut && patients.length <= 0) {
                text = 'Unable to complete request. Please try again.';
            }

            const color = isError || timedOut ? 'danger' : null;

            noResultsMessage = (
                <Text size="lg" weight="bold" color={color}>
                    {text}
                </Text>
            );
        }

        let showClearButton = false;
        forEach(patients, patient => {
            if (patient.selected) {
                showClearButton = true;
            }
        });

        const clearButtonRender = showClearButton ? (
            <Button
                id="clear-patient-selections"
                variant="opaque"
                color="secondary"
                onClick={this.handleClearClick}
                leftIcon={faXmark}
                size="sm"
            >
                Clear Selection
            </Button>
        ) : null;

        let selectPatientsMessage = null;
        if (
            patients &&
            patients.length > 0 &&
            !get(selectedPatientIds, 'length')
        ) {
            selectPatientsMessage = (
                <Text size="lg" weight="semi-bold">
                    Select one or more records to retrieve patient information
                </Text>
            );
        }

        return (
            <>
                {retrievingSpinner}

                {searchHasBegun || patientsExist ? (
                    <>
                        <PageContainerGroup className="patient-demo-results">
                            <div className="patient-demo-results-header">
                                {clearButtonRender}
                                {selectPatientsMessage}
                            </div>
                        </PageContainerGroup>
                        <PatientDemoResultTable
                            localization={localization.table}
                            items={patients}
                            onChange={this.handlePatientCheck}
                            onSelectAll={this.handleSelectAll}
                        />
                    </>
                ) : null}
                {noResultsMessage}
            </>
        );
    }
}

PatientDemoResults.propTypes = {
    loading: PropTypes.bool,
    localization: PropTypes.shape(localizationShape.patientDemoResults)
        .isRequired,
    patients: PropTypes.arrayOf(patientShape).isRequired,
    isFinishedSearch: PropTypes.bool.isRequired,
    searchHasBegun: PropTypes.bool.isRequired,
    selectedPatientIds: PropTypes.arrayOf(PropTypes.string),
    isError: PropTypes.bool.isRequired,
    errorField: PropTypes.string,
    delayed: PropTypes.bool.isRequired,
    timedOut: PropTypes.bool.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func.isRequired,
    }).isRequired,
};

PatientDemoResults.defaultProps = {
    loading: false,
    selectedPatientIds: null,
    errorField: null,
};

const sourceNameCache = {};

function addSourceNames(patients, directory) {
    return map(patients, patientObj => {
        let source = sourceNameCache[patientObj.oid];

        if (!source) {
            const sourceObj = find(directory, d => d.oid === patientObj.oid);

            source = get(sourceObj, 'entityName') || '';
            sourceNameCache[patientObj.oid] = source;
        }

        return {
            ...patientObj,
            patient: {
                ...patientObj.patient,
                source,
            },
        };
    });
}

export default connectToStores(
    applyFluxibleContext(PatientDemoResults),
    [
        'PatientResultsStore',
        'LocalizationStore',
        'DirectoryStore',
        'QueryStore',
    ],
    context => {
        const patientResultsStore = context.getStore('PatientResultsStore');
        const localiationStore = context.getStore('LocalizationStore');
        const directoryStore = context.getStore('DirectoryStore');
        const queryStore = context.getStore('QueryStore');

        const isFinishedSearch = queryStore.isTypeComplete(
            QueryType.PATIENT_DISCOVERY,
        );

        let patients = get(patientResultsStore.getState(), 'patients', []);
        patients = addSourceNames(
            patients,
            directoryStore.getState().directory,
        );

        return {
            loading: queryStore.isTypeLoading(QueryType.PATIENT_DISCOVERY),
            delayed: queryStore.isDelayed(QueryType.PATIENT_DISCOVERY),
            timedOut: queryStore.isTimedOut(QueryType.PATIENT_DISCOVERY),
            isError: queryStore.isTypeError(QueryType.PATIENT_DISCOVERY),
            errorField: queryStore.getErrorField(QueryType.PATIENT_DISCOVERY),
            localization: localiationStore.getState().patientDemoResults,
            patients,
            isFinishedSearch,
            searchHasBegun: queryStore.doesQueryOfTypeExist(
                QueryType.PATIENT_DISCOVERY,
            ),
        };
    },
);
