import moment from 'moment';
import QueryType from '../common/query/query-type';
import { issueQuery } from '../common/query/query-helper';

// eslint-disable-next-line import/prefer-default-export
export async function queryMedications(actionContext, { fields }) {
    actionContext.dispatch('ADD_MEDICATIONS_RESULTS', {
        error: null,
    });

    const buildRequestData = ({ facilityId, facilityName, queryId, user }) => {
        const demographics = {
            name: {
                firstName: fields.firstName,
                lastName: fields.lastName,
                middleName: fields.middleName,
            },
            gender: fields.gender,
            dateOfBirth: fields.dob,
            address: {
                address1: fields.address1,
                city: fields.city,
                stateProvince: fields.state,
                postalCode: fields.zip,
            },
            communicationNumbers: {
                primaryTelephone: {
                    number: fields.homePhone,
                },
            },
        };

        const requestingDemographics = {
            firstName: user.firstName,
            lastName: user.lastName,
        };

        const { pulseAggregationId } = fields;

        const sessionStore = actionContext.getStore('Session');
        const npi = sessionStore.getNpi();

        const endDate = moment().toISOString();
        const startDate = moment()
            .subtract(1, 'year')
            .toISOString();

        let fromSearchHistory = false;
        if (fields.fromSearchHistory) {
            fromSearchHistory = true;
        }

        const applicationContextStore = actionContext.getStore(
            'ApplicationContext',
        );
        const medicationsTimeout = applicationContextStore.config(
            'medicationsTimeout',
        );

        setTimeout(() => {
            // If we are still retrieving results when the timeout hits
            if (
                actionContext
                    .getStore('QueryStore')
                    .isRetrievingResults(queryId)
            ) {
                actionContext.socket.removeHandlers({ event: queryId });
                actionContext.dispatch('ADD_MEDICATIONS_ERRORS', {
                    errorCode: 7,
                    errorDescription: 'Medication Request Timed Out',
                });
                actionContext.dispatch('ADD_QUERY_RESULT', {
                    id: queryId,
                    resultRequest: {
                        serviceId: queryId,
                        results: [],
                    },
                    requestError: true,
                });
            }
        }, medicationsTimeout);

        return {
            facilityId,
            facilityName,
            requestId: queryId,
            demographics,
            tenantId: user.tenantId,
            npi,
            startDate,
            endDate,
            fromSearchHistory,
            pulseAggregationId,
            requestingUser: {
                demographics: requestingDemographics,
            },
        };
    };

    issueQuery(actionContext, QueryType.MEDICATIONS, buildRequestData);
}
