/* eslint-disable react/jsx-key */
import React from 'react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import get from 'lodash/get';
import LinkButton from '@audacious/components/components/LinkButton';
import PropTypes from 'prop-types';
import { SimpleTable } from '@audacious/components/components/Table';
import { documentResultsPropType } from '../../../../prop-types/documents';
import { retrieveDocuments } from '../../../../../actions/retrieve-document-actions';

import './document-result-table.scss';

class DocumentResultRow extends React.Component {
    constructor(props) {
        super(props);

        this.handleDocClick = this.handleDocClick.bind(this);
    }

    handleDocClick(document) {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(retrieveDocuments, document);
    }

    render() {
        const { localization, items, disabled } = this.props;

        const columns = [
            {
                headerBody: get(localization, 'title'),
                cellValuePath: 'name',
                sortable: true,
                renderCell: (cellValue, rowValue) => {
                    const docName = cellValue || '';
                    return (
                        <LinkButton
                            id="docSimpleTableSelect"
                            disabled={disabled}
                            aria-label={`View ${docName}`}
                            title={`View ${docName}`}
                            onClick={() => this.handleDocClick(rowValue)}
                        >
                            {docName}
                        </LinkButton>
                    );
                },
            },
            {
                headerBody: get(localization, 'source'),
                cellValuePath: 'classification.facility',
                sortable: true,
                renderCell: (cellValue, rowValue) => (
                    <>
                        <div className="bold" aria-label="Source facility:">
                            {cellValue || ''}
                        </div>
                        <div
                            className="cell-detail"
                            aria-label="Source department:"
                        >
                            {get(rowValue, 'classification.department') || ''}
                        </div>
                    </>
                ),
            },
            {
                headerBody: get(localization, 'serviceTime'),
                cellValuePath: 'serviceStartTime',
                sortable: true,
                renderCell: (cellValue, rowValue) => (
                    <>
                        <div className="bold" aria-label="Service start time:">
                            {cellValue || ''}
                        </div>
                        <div
                            className="cell-detail"
                            aria-label="Service stop time:"
                        >
                            {get(rowValue, 'serviceStopTime') || ''}
                        </div>
                    </>
                ),
            },
            {
                headerBody: get(localization, 'author'),
                cellValuePath: 'authors[0].person',
                sortable: true,
                renderCell: (cellValue, rowValue) => (
                    <>
                        <div className="bold" aria-label="Author:">
                            {cellValue || ''}
                        </div>
                        <div
                            className="cell-detail"
                            aria-label="Author details:"
                        >
                            {get(rowValue, 'authorDetail')}
                        </div>
                    </>
                ),
            },
            {
                headerBody: get(localization, 'authorInstitution'),
                cellValuePath: 'authors[0].institutions[0]',
                sortable: true,
                width: '200px',
            },
            {
                headerBody: get(localization, 'type'),
                cellValuePath: 'classification.type',
                sortable: true,
            },
        ];

        return (
            <SimpleTable
                className="document-results-table"
                items={items}
                columns={columns}
                pageTable
            />
        );
    }
}

DocumentResultRow.propTypes = {
    localization: PropTypes.objectOf(PropTypes.string).isRequired,
    items: PropTypes.arrayOf(documentResultsPropType),
    disabled: PropTypes.bool.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func.isRequired,
    }).isRequired,
};

DocumentResultRow.defaultProps = {
    items: null,
};

export default applyFluxibleContext(DocumentResultRow);
