import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import values from 'lodash/values';
import forEach from 'lodash/forEach';
import parseDate from 'date-fns/parse';
import startOfToday from 'date-fns/startOfToday';
import { blankFields } from '../stores/patient-form-store';
import { clear } from '../common/query/query-helper';
import { selectPatient } from './patient-discovery-actions';
import { SEARCH } from '../components/pages/routes';
import { setSelectedDocType } from './query-document-actions';
import { DocTypes } from '../stores/document-store';
import QueryType from '../common/query/query-type';
import QueryStatus from '../common/query/query-status';
import QueryDocumentStatus from '../common/query/query-document-status';
import { queryMedications } from './medications-actions';
import getMedEntitlement from '../common/util/get-med-entitlement';

export function clearSearchHistory(context) {
    context.dispatch('CLEAR_SEARCH_HISTORY');
}

export function loadSearchHistory(context, { options, params, history }) {
    const { parentRequestId } = options;

    context.dispatch('SET_HISTORY_IS_LOADING', true);

    // clear out the existing stores
    clear(context);

    let patientSearchRequest = null;

    const historyData = { data: {}, flags: {}, hasError: false };

    // standard behavior for all errors
    const handleError = () => {
        historyData.handleError = true;
        clear(context);
        history.push(SEARCH);
        context.dispatch('SET_HISTORY_IS_LOADING', false);
    };

    // called after each part of the search history is retrieved.
    // checks if all pieces have been retrieved and then ends the search and
    // populates the stores if it has
    const historyPropertyCallback = (err, service) => {
        if (historyData.hasError) {
            return;
        }

        if (err || get(service, 'response.request.status') !== 200) {
            handleError();
            return;
        }

        historyData.flags[service.id] = true;
        historyData.data[service.id] = get(service, 'response.data');

        if (
            historyData.flags['searchHistory.retrievePatientRequests'] &&
            historyData.flags['searchHistory.retrievePatientResults'] &&
            historyData.flags['searchHistory.retrieveQdRequests'] &&
            historyData.flags['searchHistory.retrieveQdResults'] &&
            historyData.flags['searchHistory.retrieveRdRequests'] &&
            historyData.flags['searchHistory.retrieveRdResults']
        ) {
            // we have retrieved all of the history data
            // fix it up and add it to the stores
            const patientRequests =
                historyData.data['searchHistory.retrievePatientRequests'];
            const patientResults =
                historyData.data['searchHistory.retrievePatientResults'];
            const qdRequests =
                historyData.data['searchHistory.retrieveQdRequests'];
            const qdResults =
                historyData.data['searchHistory.retrieveQdResults'];

            // verify that the patient request is valid
            forEach(patientRequests, item => {
                const id = get(item, 'parentRequestId');
                if (id === parentRequestId) {
                    patientSearchRequest = item;
                }
            });

            if (!patientSearchRequest) {
                handleError();
                return;
            }

            const patientResultsMap = {};
            const selectedPatientsMap = {};
            forEach(patientResults, item => {
                if (item.patientId && item.patientId.length > 0) {
                    const patientId = get(item.patientId[0], 'patientId');
                    if (patientId) {
                        patientResultsMap[patientId] = item;
                    }
                }
            });

            const qdRequestsMap = {};
            forEach(qdRequests, request => {
                qdRequestsMap[request.requestId] = request;
            });

            const qdResultsMatchingRequests = [];

            forEach(qdResults, result => {
                const { requestId } = result;
                if (qdRequestsMap[requestId]) {
                    const newResult = { ...result };
                    const request = qdRequestsMap[requestId];
                    newResult.patientId = request.patient;
                    qdResultsMatchingRequests.push(newResult);

                    // select all patients for this document
                    if (newResult.patientId && newResult.patientId.length > 0) {
                        const patientId = get(
                            newResult.patientId[0],
                            'patientId',
                        );
                        if (patientId && patientResultsMap[patientId]) {
                            const documents = get(result, 'documents', []);
                            selectedPatientsMap[patientId] =
                                patientResultsMap[patientId];

                            if (documents.length) {
                                patientResultsMap[patientId].status =
                                    QueryDocumentStatus.SUCCESS;
                            } else {
                                patientResultsMap[patientId].status =
                                    QueryDocumentStatus.NO_RESULTS;
                            }
                        }
                    }
                }
            });

            context.dispatch('ADD_PATIENT_RESULT', {
                results: values(patientResultsMap),
            });

            // select patients
            forEach(values(selectedPatientsMap), patient => {
                selectPatient(context, patient);
            });

            forEach(qdResultsMatchingRequests, item => {
                context.dispatch('ADD_QUERY_DOCUMENT_RESULT', {
                    results: item,
                });
            });

            // set the selected doc type
            if (qdResultsMatchingRequests.length > 0) {
                setSelectedDocType(context, DocTypes.DOCUMENTS);
            }

            // set the patient fields
            const patientFields = cloneDeep(
                patientSearchRequest.patientDemographics,
            );
            const formattedFields = merge({}, blankFields, patientFields);

            // convert fields to formats used in the form
            formattedFields.dob = parseDate(
                patientFields.dob,
                'yyyyMMdd',
                startOfToday(),
            );

            context.dispatch('SET_PD_REQUEST_ID', parentRequestId);
            context.dispatch('SET_PATIENT_FORM_FIELDS', formattedFields);

            // set query statuses
            context.dispatch('ADD_QUERY', {
                id: patientSearchRequest.parentRequestId,
                type: QueryType.PATIENT_DISCOVERY,
                status: QueryStatus.COMPLETE,
            });

            forEach(qdRequests, item => {
                context.dispatch('ADD_QUERY', {
                    id: item.requestId,
                    type: QueryType.QUERY_DOCUMENT,
                    status: QueryStatus.COMPLETE,
                });
            });

            context.dispatch('SET_HISTORY_IS_LOADING', false);

            const hasMedEntitlement = getMedEntitlement(context);

            set(patientFields, 'fromSearchHistory', true);

            if (hasMedEntitlement) {
                // make the medication request based on the patient request
                queryMedications(context, { fields: patientFields });
            }
        }
    };

    // begin loading
    context.service.searchHistory.retrievePatientRequests(
        { options, params },
        historyPropertyCallback,
    );
    context.service.searchHistory.retrievePatientResults(
        { options, params },
        historyPropertyCallback,
    );
    context.service.searchHistory.retrieveQdRequests(
        { options, params },
        historyPropertyCallback,
    );
    context.service.searchHistory.retrieveQdResults(
        { options, params },
        historyPropertyCallback,
    );
    context.service.searchHistory.retrieveRdRequests(
        { options, params },
        historyPropertyCallback,
    );
    context.service.searchHistory.retrieveRdResults(
        { options, params },
        historyPropertyCallback,
    );
}

export function clearPatientRequests(context) {
    context.dispatch('SET_SEARCH_HISTORY', []);
}

export function setHistoryRequestId(context, id) {
    context.dispatch('SET_HISTORY_REQUEST_ID', id);
}
