import get from 'lodash/get';
import some from 'lodash/some';
import every from 'lodash/every';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import toLower from 'lodash/toLower';
import words from 'lodash/words';
import toString from 'lodash/toString';
import formatDate from './format-date';

/**
 * Returns a filtered collection that only contains items whose
 * displayed fields contains text matching each search input word.
 *
 * Each item in the collection is kept in the returned filtered collection
 * if any of that item's values at the paths (from the searchConfig array)
 * in that item at least partially match each search word.
 *
 * @param {*} collection the collection to filter
 * @param {*} searchInput the search string used to filter
 * @param {*} searchConfig an array of objects with paths to values
 * in each item in the collection to filter against. Each object may also
 * contain an isDate boolean, which will match number search words if the
 * year, month or day components completely match a word.
 * Example: [ { path: 'dateVal', isDate: true }, { path: 'exampleObj.textVal' } ]
 */
function searchFilter(collection, searchInput, searchConfig) {
    const searchWords = words(toLower(searchInput), /[^(/|\-|\s)]+/g);

    return filter(collection, item => {
        const targets = flatMap(searchConfig, conf => {
            const target = get(item, conf.path);

            if (conf.isDate) {
                return words(formatDate(target, 'YYYY MM DD', 'MM/DD/YYYY'));
            }

            if (conf.isInteger) {
                return words(toString(target));
            }

            return target || [];
        });

        return every(searchWords, word =>
            some(targets, target => {
                // eslint-disable-next-line no-restricted-globals
                if (!isNaN(target)) {
                    // Numbers much match exactly
                    return target === word;
                }

                return toLower(target).includes(word);
            }),
        );
    });
}

export default searchFilter;
