import CommonStore from '@audacious/web-common/fluxible/CommonStore';

class PatientQueryHistoryStore extends CommonStore {}

PatientQueryHistoryStore.storeName = 'PatientQueryHistoryStore';
PatientQueryHistoryStore.handlers = {
    UPDATE_PATIENT_QUERY_HISTORY: 'setState',
    LOGOUT: 'resetState',
};

export default PatientQueryHistoryStore;
