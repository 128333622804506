import merge from 'lodash/merge';

import loadFacilities from './load-facilities';
import loadLocations from './load-locations';

const DEFAULT_MOCK_SERVICES = {
    loadFacilities,
    loadLocations,
};

export default ({
    enabled = false,
    include = [],
    exclude = [],
    immediate = true,
    services = {},
}) => ({
    enabled,
    include,
    exclude,
    immediate,
    services: merge(DEFAULT_MOCK_SERVICES, services),
});
