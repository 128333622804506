import { stores as facilityStores } from '@audacious/pulse-facility-selection';
import LocalizationStore from './localization-store';
import UserStore from './user-store';
import PatientQueryHistoryStore from './patient-query-history-store';
import DocumentStore from './document-store';
import DocumentViewStore from './document-view-store';
import DirectoryStore from './directory-store';
import ActiveTenantStore from './active-tenant-store';
import PatientResultsStore from './patient-results-store';
import QueryStore from './query-store';
import SearchHistoryStore from './search-history-store';
import PatientFormStore from './patient-form-store';
import MedicationsResultsStore from './medications-results-store';
import EpraPreferencesStore from './epra-preferences-store';

export default [
    LocalizationStore,
    UserStore,
    PatientQueryHistoryStore,
    DocumentStore,
    DirectoryStore,
    ActiveTenantStore,
    PatientResultsStore,
    QueryStore,
    DocumentViewStore,
    SearchHistoryStore,
    PatientFormStore,
    MedicationsResultsStore,
    EpraPreferencesStore,
    ...facilityStores,
];
