import get from 'lodash/get';

function loadDirectorySuccess(context, { response }) {
    context.dispatch('UPDATE_DIRECTORY', response.data);
}
loadDirectorySuccess.displayName = 'loadDirectorySuccess';

export default config => ({
    serviceName: 'loadDirectory',
    authenticate: true,
    config: {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET',
        url: get(config, 'endpoints.pulse.directory.retrieve'),
    },
    onSuccess: loadDirectorySuccess,
});
