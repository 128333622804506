/* eslint-disable no-param-reassign */
export function formatPatientName({ firstName, middleName, lastName }) {
    firstName = firstName || '';
    middleName = middleName || '';
    lastName = lastName || '';

    return `${lastName || ''}${lastName && firstName ? ',' : ''} ${firstName ||
        ''} ${middleName || ''}`;
}

export function formatGender(gender) {
    let upperGender = gender || '';
    upperGender = upperGender.toUpperCase();

    if (upperGender === 'M') {
        return 'Male';
    }
    if (upperGender === 'F') {
        return 'Female';
    }
    if (upperGender === 'U') {
        return 'Unknown';
    }
    return '';
}

export function formatAddress({ address1, address2, city, state, zip }) {
    address1 = address1 || '';
    address2 = address2 || '';
    city = city || '';
    state = state || '';
    zip = zip || '';

    let result = '';
    let street = '';

    if (address1) {
        street = address1;
        if (address2) street += ` ${address2}`;
    }

    result = street;

    if (city || state || zip) {
        if (result) {
            result += ',';
        }

        if (city) result += ` ${city}`;

        if (city && state) result += ',';

        if (state) result += ` ${state}`;
        if (zip) result += ` ${zip}`;
    }

    return result;
}

export function formatPhoneNumber(phoneNumber) {
    let phone = phoneNumber || '';
    const countryCodeMatch = phone.match(/\+[0-9]+/g);
    phone = phone.replace(/\+[0-9]+/g, '');

    const onlyNumPhone = phone.replace(/[^\d]/g, '');
    let formattedPhone = onlyNumPhone.replace(
        /(\d{3})(\d{3})(\d{4})/,
        '$1-$2-$3',
    );

    if (countryCodeMatch) {
        formattedPhone = `${countryCodeMatch[0]} ${formattedPhone}`;
    }

    return formattedPhone;
}
