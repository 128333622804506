/* eslint-disable react/no-access-state-in-setstate */
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';

const initialState = {
    data: null,
    type: null,
    transformDocumentLoading: false,
    selectedDocument: null,
};

class DocumentViewStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);
    }

    addRetrieveDocumentResult(results) {
        if (results) {
            this.setState({
                transformDocumentLoading: true,
            });
        }
        return this.getState();
    }

    addTransformedDocumentResult(results) {
        if (results) {
            const { data, type } = results;

            this.setState({
                type,
                data,
                transformDocumentLoading: false,
            });
        }
        return this.getState();
    }

    setRetrieveDocumentStatus(results) {
        if (!isNil(get(results, 'isError'))) {
            this.setState({
                isError: results.isError,
                transformDocumentLoading: false,
            });
        }
    }

    startRdTransformLoading(selectedDocument) {
        this.setState({
            selectedDocument,
            transformDocumentLoading: true,
            isError: false,
        });
    }

    endViewingDocument() {
        this.setState({
            data: null,
            type: null,
        });
    }
}

DocumentViewStore.storeName = 'DocumentViewStore';
DocumentViewStore.handlers = {
    ADD_RETRIEVE_DOCUMENT_RESULT: 'addRetrieveDocumentResult',
    UPDATE_TRANSFORMED_DOCUMENT: 'addTransformedDocumentResult',
    SET_RETRIEVE_DOCUMENT_STATUS: 'setRetrieveDocumentStatus',
    START_RD_TRANSFORM_LOADING: 'startRdTransformLoading',
    END_VIEWING_DOCUMENT: 'endViewingDocument',
    CLEAR_VIEW_DOCUMENTS: 'resetState',
    LOGOUT: 'resetState',
};

export default DocumentViewStore;
