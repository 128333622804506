import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import get from 'lodash/get';
import QueryType from '../common/query/query-type';
import QueryDocumentStatus from '../common/query/query-document-status';
import { issueQuery } from '../common/query/query-helper';

export async function queryDocuments(actionContext, params) {
    const associatedIds = [];

    const { patients, documentFilterType } = params;

    const patientsToQuery = filter(patients, patient => {
        if (
            patient.selected &&
            patient.status !== QueryDocumentStatus.SUCCESS
        ) {
            const patientId = get(patient, 'patientId[0].patientId');
            associatedIds.push(patientId);

            return true;
        }

        return false;
    });

    if (patientsToQuery.length === 0) {
        return;
    }

    const buildRequestData = ({
        facilityId,
        facilityName,
        queryId,
        user,
        originalPdRequestId,
    }) => {
        const patientsClone = cloneDeep(patientsToQuery);
        const requestingDemographics = {
            firstName: user.firstName,
            lastName: user.lastName,
        };

        const orgPatients = patientsClone.map(patient => ({
            oid: patient.oid,
            patientId: patient.patientId,
        }));

        return {
            facilityId,
            facilityName,
            originalPdRequestId,
            requestId: queryId,
            tenantId: user.tenantId,
            orgPatients,
            requestingUser: {
                demographics: requestingDemographics,
            },
            filters: [documentFilterType],
        };
    };

    actionContext.dispatch('SET_QUERY_DOCUMENT_STATUS', {
        patientIds: associatedIds,
    });

    issueQuery(
        actionContext,
        QueryType.QUERY_DOCUMENT,
        buildRequestData,
        associatedIds,
    );
}

export function setSelectedDocType(actionContext, docType) {
    actionContext.dispatch('SET_SELECTED_DOC_TYPE', docType);
}
