import get from 'lodash/get';

function retrieveActivationFacilitiessSuccess(context, { response }) {
    context.dispatch('ADD_ACTIVE_FACILITIES', response.data);
}
retrieveActivationFacilitiessSuccess.displayName =
    'retrieveActivationFacilitiessSuccess';

export default config => ({
    serviceName: 'tenant.activation.retrieveActivationFacilities',
    authenticate: true,
    config: {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET',
        url: get(
            config,
            'endpoints.pulse.tenant.activation.retrieveFacilities',
        ),
    },
    onSuccess: retrieveActivationFacilitiessSuccess,
});
