import cloneDeep from 'lodash/cloneDeep';
import QueryType from '../common/query/query-type';
import { setup, issueQuery, clear } from '../common/query/query-helper';

export async function setupSearchPage(actionContext) {
    const session = actionContext.getStore('Session');
    const sessionKey = session.getSessionKey();

    setup(actionContext, sessionKey, session.getFacility().getId());
}

export async function queryPatients(actionContext, { fields, requestId }) {
    const buildRequestData = ({
        facilityId,
        facilityName,
        facilityZipCode,
        queryId,
        user,
    }) => {
        const demographics = cloneDeep(fields);
        const requestingDemographics = {
            firstName: user.firstName,
            lastName: user.lastName,
        };
        const { pulseAggregationId } = fields;
        delete demographics.pulseAggregationId;

        return {
            facilityId,
            facilityName,
            facilityZipCode,
            requestId: queryId,
            pulseAggregationId,
            demographics,
            tenantId: user.tenantId,
            requestingUser: {
                demographics: requestingDemographics,
            },
        };
    };

    issueQuery(
        actionContext,
        QueryType.PATIENT_DISCOVERY,
        buildRequestData,
        null,
        requestId,
    );
}

export async function clearQueries(actionContext) {
    clear(actionContext);
}

export async function selectPatient(actionContext, patient) {
    actionContext.dispatch('CHANGE_PATIENTS_SELECTED', {
        patient,
        select: true,
    });
}

export async function deselectPatient(actionContext, patient) {
    actionContext.dispatch('CHANGE_PATIENTS_SELECTED', {
        patient,
        select: false,
    });
}

export async function selectAllPatients(actionContext) {
    actionContext.dispatch('CHANGE_PATIENTS_SELECTED', {
        select: true,
    });
}

export async function deselectAllPatients(actionContext) {
    actionContext.dispatch('CHANGE_PATIENTS_SELECTED', {
        select: false,
    });
}

export function setPdRequestId(actionContext, id) {
    actionContext.dispatch('SET_PD_REQUEST_ID', id);
}
