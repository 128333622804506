export default {
    '2b03c195-d9ca-405a-9492-778cc668cae9': [
        {
            locationCode: 'LOCODE10',
            locationId: '4f66036e-5cd0-4888-8cd6-60250c7a5053',
            locationName: 'East Main Facility',
            tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
        },
        {
            locationCode: 'LOCODE13',
            locationId: 'a02cc9ea-6548-49fa-a7c4-968b41159f4d',
            locationName: 'Central Baltimore Facility',
            tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
        },
        {
            locationCode: 'LOCODE12',
            locationId: 'a39cf443-1b0c-49ac-afb5-6b1355e9a452',
            locationName: 'East Main Facility',
            tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
        },
        {
            locationCode: 'LOCODE11',
            locationId: 'a94c6764-b8eb-4f4d-aefc-04773bf6fd9b',
            locationName: 'East Baltimore Facility',
            tenantId: '2b03c195-d9ca-405a-9492-778cc668cae9',
        },
    ],
};
