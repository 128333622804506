/* eslint-disable react/jsx-key */
import React from 'react';
import get from 'lodash/get';
import replace from 'lodash/replace';
import PropTypes from 'prop-types';
import { SimpleTable } from '@audacious/components/components/Table';
import { Text } from '@audacious/components/components/Typography';
import Popover from '@audacious/components/components/Popover';
import LinkButton from '@audacious/components/components/LinkButton';
import './medications-results-table.scss';

// eslint-disable-next-line react/prefer-stateless-function
class MedicationsResultsTable extends React.Component {
    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
    }

    // eslint-disable-next-line react/no-unused-class-component-methods
    getSortedMedications() {
        if (!this.tableRef.current) {
            return [];
        }

        const items = this.tableRef.current.getFilteredItems();
        return items;
    }

    render() {
        const { localization, items } = this.props;

        const columns = [
            {
                headerBody: get(localization, 'name'),
                sortable: true,
                cellValuePath: 'name',
                width: '200px',
                renderCell: (cellValue, medication) => (
                    <div>
                        <Text weight="semi-bold">{cellValue}</Text>
                        <Text size="sm">
                            {' '}
                            {get(medication, 'sig[0].SigText')}
                        </Text>
                    </div>
                ),
            },
            {
                headerBody: get(localization, 'fillDate'),
                sortable: true,
                cellValuePath: 'fillDate',
                sortAsDate: true,
                width: '125px',
            },
            {
                headerBody: get(localization, 'writtenDate'),
                sortable: true,
                cellValuePath: 'writtenDate',
                sortAsDate: true,
                width: '150px',
            },
            {
                headerBody: get(localization, 'quantity'),
                sortable: true,
                cellValuePath: 'quantity',
                cellSortPath: 'quantitySort',
                width: '80px',
            },
            {
                headerBody: get(localization, 'days'),
                sortable: true,
                cellValuePath: 'days',
                cellSortPath: 'daysSort',
                width: '80px',
            },
            {
                headerBody: get(localization, 'refills'),
                sortable: true,
                cellValuePath: 'refills',
                cellSortPath: 'refillsSort',
                width: '100px',
            },
            {
                headerBody: get(localization, 'prescriber'),
                sortable: true,
                width: '150px',
                cellValuePath: 'prescriber',
                cellSortPath: 'prescriber.0.Item.Name.LastName',
                renderCell: (cellValue, medication, index) => {
                    const prescriberAddress = get(
                        medication,
                        'prescriberAddress',
                    );
                    const prescriberTelephone = get(
                        medication,
                        'prescriberPhone',
                    );
                    const businessName = get(
                        medication,
                        'prescriber.0.Item.PracticeLocation.BusinessName',
                    );
                    const prescriberFirstName = get(
                        medication,
                        'prescriber.0.Item.Name.FirstName',
                        '',
                    );
                    const prescriberMiddleName = get(
                        medication,
                        'prescriber.0.Item.Name.MiddleName',
                        '',
                    );
                    const prescriberLastName = get(
                        medication,
                        'prescriber.0.Item.Name.LastName',
                        '',
                    );

                    const formattedPrescriberName =
                        prescriberFirstName || prescriberLastName
                            ? `${replace(
                                  prescriberFirstName,
                                  null,
                                  '',
                              )} ${replace(
                                  prescriberMiddleName,
                                  null,
                                  '',
                              )} ${replace(prescriberLastName, null, '')}`
                            : '-';

                    let prescriberPopover;
                    if (
                        prescriberAddress ||
                        prescriberTelephone ||
                        businessName
                    ) {
                        prescriberPopover = (
                            <>
                                <div>{formattedPrescriberName}</div>
                                <div>{businessName}</div>
                                <div>{prescriberAddress}</div>
                                <div>Tel&#58; {prescriberTelephone}</div>
                            </>
                        );
                    }

                    return prescriberPopover ? (
                        <Popover
                            anchor={LinkButton}
                            anchorProps={{
                                children: formattedPrescriberName,
                            }}
                            id={`med-${index}-prescriber`}
                        >
                            {prescriberPopover}
                        </Popover>
                    ) : (
                        formattedPrescriberName
                    );
                },
            },
            {
                headerBody: get(localization, 'pharmacy'),
                sortable: true,
                width: '150px',
                cellValuePath: 'pharmacy',
                cellSortPath: 'pharmacy.0.BusinessName',
                renderCell: (cellValue, medication, index) => {
                    const pharmacyAddress = get(medication, 'pharmacyAddress');
                    const pharmacyTelephone = get(medication, 'pharmacyPhone');
                    const pharmacyBusinessName = get(
                        medication,
                        'pharmacy.0.BusinessName',
                    );

                    let pharmacyPopover;
                    if (pharmacyAddress || pharmacyTelephone) {
                        pharmacyPopover = (
                            <>
                                <div>{pharmacyBusinessName}</div>
                                <div>{pharmacyAddress}</div>
                                <div>Tel&#58; {pharmacyTelephone}</div>
                            </>
                        );
                    }

                    return pharmacyPopover ? (
                        <Popover
                            anchor={LinkButton}
                            anchorProps={{
                                children: pharmacyBusinessName || '-',
                            }}
                            id={`med-${index}-pharmacy`}
                        >
                            {pharmacyPopover}
                        </Popover>
                    ) : (
                        pharmacyBusinessName
                    );
                },
            },
        ];

        return (
            <SimpleTable
                ref={this.tableRef}
                items={items}
                columns={columns}
                minWidth={1000}
                initialSortPath="fillDate"
                initialSortDescending
            />
        );
    }
}

MedicationsResultsTable.propTypes = {
    localization: PropTypes.objectOf(PropTypes.string).isRequired,
    // TODO: Fix proptype
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.arrayOf(PropTypes.object),
};

MedicationsResultsTable.defaultProps = {
    items: null,
};

export default MedicationsResultsTable;
