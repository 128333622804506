export default {
    heading: {
        welcome: 'Welcome to PULSE',
    },
    form: {
        support: {
            field: {
                phone: {
                    value: '(302) 480-1770',
                },
                email: {
                    value: 'servicedesk@pulse.org',
                },
            },
        },
    },
    content: {
        welcome: {
            announcement:
                'Patient Unified Lookup System for Emergencies (PULSE) is designed to provide healthcare professionals caring for displaced patients, access to their vital health information in an event of a disaster.\n\nThis health information, and concise health record documents, may be drawn from disparate systems.\n\nThe disparate systems could include HIOs, Ambulatory Practices, Emergency Medical Service, Agencies, etc.',
        },
        support:
            'Please contact your PULSE administrator with support questions.',
    },
};
