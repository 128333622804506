import isNil from 'lodash/isNil';

function getMedEntitlement(context) {
    const SessionStore = context.getStore('Session');
    const npi = SessionStore.getNpi();

    const sessionStore = context.getStore('Session');
    const hasEntitlement = sessionStore.hasEntitlement('medications');

    return hasEntitlement && !isNil(npi);
}

export default getMedEntitlement;
