import React from 'react';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import Spinner from '@audacious/components/components/Spinner';
import Button from '@audacious/components/components/Button';
import TableMessage from '@audacious/components/components/TableMessage';
import { PageContainerGroup } from '@audacious/components/components/Page';
import { SubHeading, Text } from '@audacious/components/components/Typography';
import { Row, Column } from '@audacious/components/components/Grid';
import TextInput from '@audacious/components/components/TextInput';
import { faMagnifyingGlass } from '@audacious/icons/regular/faMagnifyingGlass';
import filter from 'lodash/filter';
import get from 'lodash/get';
import some from 'lodash/some';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import { localizationShape } from '../../../../prop-types/localization';
import { documentResultsPropType } from '../../../../prop-types/documents';
import { documentViewPropType } from '../../../../prop-types/document-view';
import QueryType from '../../../../../common/query/query-type';
import DocumentResultTable from './document-result-table';
import PatientDemoResults from '../patient-demo-results/patient-demo-results';
import searchFilter from '../../../../../common/util/search-filter';
import { queryDocuments } from '../../../../../actions/query-document-actions';
import { patientResultsPropType } from '../../../../prop-types/patient-results';
import QueryDocumentStatus from '../../../../../common/query/query-document-status';

import './document-results.scss';

class DocumentResults extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchInput: '',
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handleRetrieveDocumentsClick = this.handleRetrieveDocumentsClick.bind(
            this,
        );
    }

    handleSearch(searchInput) {
        this.setState({ searchInput });
    }

    handleRetrieveDocumentsClick() {
        const {
            localization,
            patientResults,
            fluxibleContext: { executeAction },
        } = this.props;

        const patients = get(patientResults, 'patients', []);
        const documentFilterType = get(localization, 'documentFilterType');
        executeAction(queryDocuments, { patients, documentFilterType });
    }

    render() {
        const {
            documentResults: { documents },
            localization,
            qdLoading,
            rdLoading,
            qdDelayed,
            qdTimedOut,
            rdDelayed,
            rdTimedOut,
            documentData,
            documentViewResults,
            queryDocumentStarted,
            selectedPatientIds,
            patientResults,
        } = this.props;
        const { searchInput } = this.state;

        // Only show documents that were selected in the patient results list
        const selectedDocs = filter(documents, doc => {
            const patientId = get(doc, 'patientId[0].patientId');

            return some(
                selectedPatientIds,
                selectedId => selectedId === patientId,
            );
        });

        const searching = !isEmpty(trim(searchInput));
        let matchingDocs = selectedDocs;

        // Only show documents matching the user entered search
        if (searching) {
            matchingDocs = searchFilter(selectedDocs, searchInput, [
                { path: 'name' },
                { path: 'classification.facility' },
                { path: 'classification.department' },
                { path: 'serviceStartTime', isDate: true },
                { path: 'serviceStopTime', isDate: true },
                { path: 'authors[0].person' },
                { path: 'authors[0].role' },
                { path: 'authors[0].specialty' },
                { path: 'authors[0].institutions[0]' },
                { path: 'classification.type' },
            ]);
        }

        matchingDocs = matchingDocs || [];

        const patients = get(patientResults, 'patients', []);
        const retrieveDocumentsButtonIsDisabled = !some(patients, patient => {
            const patientId = get(patient, 'patientId.0.patientId', '');
            if (includes(selectedPatientIds, patientId)) {
                const status = get(patient, 'status', '');

                return (
                    status !== QueryDocumentStatus.LOADING &&
                    status !== QueryDocumentStatus.SUCCESS
                );
            }

            return false;
        });

        const retrieveDocumentsButton =
            selectedPatientIds.length > 0 ? (
                <PageContainerGroup>
                    <Button
                        id="retrieve-documents-btn"
                        title="Retrieve Documents"
                        onClick={this.handleRetrieveDocumentsClick}
                        disabled={retrieveDocumentsButtonIsDisabled}
                        color="primary"
                        variant="fill"
                    >
                        Request Documents
                    </Button>
                </PageContainerGroup>
            ) : null;

        return (
            <>
                <PatientDemoResults selectedPatientIds={selectedPatientIds} />
                {retrieveDocumentsButton}
                {selectedDocs.length > 0 ? (
                    <>
                        <PageContainerGroup className="retrieved-documents-header">
                            <Row>
                                <Column>
                                    <SubHeading level="4">
                                        Retrieved Documents
                                    </SubHeading>
                                </Column>
                            </Row>
                            <Row gutter="16" enableAfter>
                                <Column width={[null, '8']}>
                                    <Text>
                                        Certain clinical information may be
                                        missing from the list below. You should
                                        independently verify clinical history
                                        with the patient.
                                    </Text>
                                </Column>
                                <Column width={[null, '4']}>
                                    <TextInput
                                        id="searchDocument"
                                        placeholder="Search..."
                                        value={searchInput}
                                        leftIcon={{
                                            icon: faMagnifyingGlass,
                                        }}
                                        aria-label="Search Documents"
                                        onChange={this.handleSearch}
                                        size="sm"
                                    />
                                </Column>
                            </Row>
                            {rdLoading ? (
                                // This for when a document is being opened.
                                <div className="results-info-doc-view">
                                    <Spinner size="xs" />
                                    {!rdDelayed
                                        ? ' Retrieving results...'
                                        : ' Document retrieval still in process.'}
                                </div>
                            ) : null}
                            {rdTimedOut ? (
                                <Text color="danger">
                                    Document retrieval timed out. Please try
                                    again!
                                </Text>
                            ) : null}
                            {get(documentViewResults, 'isError', false) &&
                            !rdTimedOut ? (
                                <Text color="danger">
                                    Unable to complete request. Please try
                                    again.
                                </Text>
                            ) : null}
                        </PageContainerGroup>
                        <DocumentResultTable
                            localization={localization.table}
                            items={matchingDocs}
                            disabled={rdLoading || !isNil(documentData)}
                        />
                        {matchingDocs.length === 0 ? (
                            <div className="results-info doc-error">
                                No matching documents!
                            </div>
                        ) : null}
                        {qdLoading ? (
                            // This for while the document list is being loaded
                            // You will only see this while there are still QD queries running
                            // and some of the documents have already been loaded.
                            <>
                                <Spinner size="xs" />
                                {!qdDelayed
                                    ? ' Retrieving results...'
                                    : ' Document list still loading.'}
                            </>
                        ) : null}
                    </>
                ) : null}
                {selectedDocs.length === 0 &&
                !qdLoading &&
                !qdTimedOut &&
                queryDocumentStarted ? (
                    <TableMessage header="No documents found." />
                ) : null}
            </>
        );
    }
}

DocumentResults.propTypes = {
    qdLoading: PropTypes.bool,
    rdLoading: PropTypes.bool,
    qdDelayed: PropTypes.bool,
    qdTimedOut: PropTypes.bool,
    rdDelayed: PropTypes.bool,
    rdTimedOut: PropTypes.bool,
    documentResults: documentResultsPropType,
    localization: PropTypes.shape(localizationShape.documentResults).isRequired,
    documentViewResults: PropTypes.shape(documentViewPropType),
    queryDocumentStarted: PropTypes.bool.isRequired,
    documentData: PropTypes.string,
    selectedPatientIds: PropTypes.arrayOf(PropTypes.string),
    patientResults: patientResultsPropType,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func.isRequired,
    }).isRequired,
};

DocumentResults.defaultProps = {
    qdLoading: false,
    rdLoading: false,
    qdDelayed: false,
    qdTimedOut: false,
    rdDelayed: false,
    rdTimedOut: false,
    documentResults: null,
    documentViewResults: null,
    documentData: null,
    selectedPatientIds: null,
    patientResults: null,
};

export default connectToStores(
    applyFluxibleContext(DocumentResults),
    [
        'DocumentStore',
        'LocalizationStore',
        'QueryStore',
        'DocumentViewStore',
        'PatientResultsStore',
    ],
    context => {
        const documentStore = context.getStore('DocumentStore');
        const localizationStore = context.getStore('LocalizationStore');
        const queryStore = context.getStore('QueryStore');
        const documentViewStore = context.getStore('DocumentViewStore');
        const patientResultsStore = context.getStore('PatientResultsStore');

        return {
            qdLoading: queryStore.isTypeLoading(QueryType.QUERY_DOCUMENT),
            qdDelayed: queryStore.isDelayed(QueryType.QUERY_DOCUMENT),
            qdTimedOut: queryStore.isTimedOut(QueryType.QUERY_DOCUMENT),
            rdDelayed: queryStore.isDelayed(QueryType.RETRIEVE_DOCUMENT),
            rdTimedOut: queryStore.isTimedOut(QueryType.RETRIEVE_DOCUMENT),
            rdLoading: queryStore.isTypeLoading(QueryType.RETRIEVE_DOCUMENT),

            documentData: documentViewStore.getState().data,
            documentResults: documentStore.getState(),
            localization: localizationStore.getState().documentResults,
            documentViewResults: documentViewStore.getState(),
            patientResults: patientResultsStore.getState(),
        };
    },
);
