import get from 'lodash/get';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';

const STORAGE_KEY = 'activeTenantState';

const initialState = {};

class ActiveTenantStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);

        try {
            const localState = localStorage.getItem(STORAGE_KEY);
            this.state = JSON.parse(localState);
        } catch (err) {
            this.state = {};
        }
    }

    setState(state) {
        this.state = JSON.parse(JSON.stringify(state));

        const saveState = JSON.parse(JSON.stringify(state));
        localStorage.setItem(STORAGE_KEY, JSON.stringify(saveState));

        this.emitChange();
    }

    setActivation(activationId) {
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            ...this.state,
            activationId,
        });
    }

    setActivations(activations) {
        this.setState({
            // eslint-disable-next-line react/no-access-state-in-setstate
            ...this.state,
            activations,
        });
    }

    getActivations() {
        get(this.getState(), 'activations', []);
    }
}

ActiveTenantStore.storeName = 'ActiveTenantStore';
ActiveTenantStore.handlers = {
    SET_ACTIVE_TENANT: 'setState',
    SET_ACTIVATION: 'setActivation',
    SET_ACTIVATIONS: 'setActivations',
    LOGOUT: 'resetState',
};

export default ActiveTenantStore;
