import cloneDeep from 'lodash/cloneDeep';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';

export const blankFields = {
    lastName: '',
    middleName: '',
    firstName: '',
    dob: null,
    gender: null,
    zip: '',
    address1: '',
    address2: '',
    city: '',
    state: null,
    ssn: '',
    homePhone: '',
};

const initialState = {
    fields: blankFields,
};

class PatientFormStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);
    }

    getFields() {
        return this.getState().fields;
    }

    // eslint-disable-next-line class-methods-use-this
    getBlankFields() {
        return cloneDeep(blankFields);
    }

    setFields(fields) {
        const state = this.getState();
        state.fields = fields;
        this.setState(state);
    }

    clearFields() {
        const state = this.getState();
        state.fields = cloneDeep(initialState.fields);
        this.setState(state);
    }
}

PatientFormStore.storeName = 'PatientFormStore';

PatientFormStore.handlers = {
    SET_PATIENT_FORM_FIELDS: 'setFields',
    CLEAR_PATIENT_FORM_FIELDS: 'clearFields',
    LOGOUT: 'resetState',
};

export default PatientFormStore;
