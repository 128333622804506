/* eslint-disable react/no-access-state-in-setstate */
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import formatDate from '../common/util/format-date';

export const DocTypes = {
    DOCUMENTS: 'documents',
    MEDICATIONS: 'medications',
    CONDITIONS: 'conditions',
};

const initialState = {
    documents: [],
    selectedDocType: null,
};

function formatDocument(document) {
    const doc = document;
    if (!doc) {
        return doc;
    }

    doc.name = get(document, 'name') || 'CCDA';

    doc.serviceStartTime = formatDate(
        get(doc, 'serviceStartTime', ''),
        'YYYY-MM-DD HH:mm',
    );
    doc.serviceStopTime = formatDate(
        get(doc, 'serviceStopTime', ''),
        'YYYY-MM-DD HH:mm',
    );

    const role = get(doc, 'authors[0].role') || '';
    const specialty = get(doc, 'authors[0].specialty') || '';
    doc.authorDetail = `${role}${role && specialty ? ', ' : ''}${specialty}`;

    return doc;
}

class DocumentStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);
    }

    // Use callback in setState when referencing the previous state
    addResults({ results, oid }) {
        if (results) {
            const documents = results.documents.map(document => {
                const formattedDoc = formatDocument(document);

                return {
                    ...formattedDoc,
                    patientId: results.patientId,
                    // Each result may have a sub-OID that correlates to a sub organization.
                    oid: oid || results.oid,
                };
            });

            const newDocuments = JSON.parse(
                JSON.stringify(this.state.documents),
            );

            forEach(documents, document => {
                if (
                    !some(
                        newDocuments,
                        d => d.documentId === document.documentId,
                    )
                ) {
                    newDocuments.push(document);
                }
            });

            const state = this.getState();
            state.documents = newDocuments;
            this.setState(state);
        }

        return this.getState();
    }

    getSelectedDocType() {
        return get(this.getState(), 'selectedDocType', null);
    }

    setSelectedDocType(docType) {
        const state = this.getState();
        state.selectedDocType = docType;
        this.setState(state);
    }

    clearResults() {
        this.setState(cloneDeep(initialState));
    }

    logout() {
        this.setState(cloneDeep(initialState));
    }
}

DocumentStore.storeName = 'DocumentStore';
DocumentStore.handlers = {
    ADD_QUERY_DOCUMENT_RESULT: 'addResults',
    CLEAR_DOCUMENT_RESULTS: 'clearResults',
    SET_SELECTED_DOC_TYPE: 'setSelectedDocType',
    LOGOUT: 'resetState',
};

export default DocumentStore;
