import get from 'lodash/get';

import { createMockServiceConfiguration } from '../../services';
import socket from '../../socket';

export default config => {
    const contextConfiguration = config;
    contextConfiguration.services = createMockServiceConfiguration(
        get(config, 'services', {}),
    );
    contextConfiguration.socket = socket(get(config, 'socket', {}));
    return contextConfiguration;
};
