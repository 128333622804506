import React, { PureComponent } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import SearchContent from './search-content/search-content';
import SearchHistoryContent from './search-history-content/search-history-content';
import { SEARCH_HISTORY } from '../routes';

class SearchPage extends PureComponent {
    constructor(...args) {
        super(...args);

        this.state = {};
    }

    render() {
        return (
            <Switch>
                <Route exact path="/search" component={SearchContent} />
                <Route
                    exact
                    path={SEARCH_HISTORY}
                    component={SearchHistoryContent}
                />
                <Route path={`${SEARCH_HISTORY}/*`}>
                    <Redirect to={SEARCH_HISTORY} />
                </Route>
                <Route
                    exact
                    path="/search/:searchId"
                    component={SearchContent}
                />
                <Route path="/search/:searchId/*">
                    <Redirect to="/search" />
                </Route>
            </Switch>
        );
    }
}

// eslint-disable-next-line no-class-assign
export default withRouter(SearchPage);
