import CommonStore from '@audacious/web-common/fluxible/CommonStore';

import defaultLocalization from '../localization/defaultLocalization';

class LocalizationStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, defaultLocalization);
    }
}

LocalizationStore.storeName = 'LocalizationStore';
LocalizationStore.handlers = {
    UPDATE_LOCALIZATION: 'setState',
};

export default LocalizationStore;
