import React from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import {
    PageContainer,
    PageContainerGroup,
} from '@audacious/components/components/Page';
import TabBar from '@audacious/components/components/TabBar';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import { patientResultsPropType } from '../../../../prop-types/patient-results';
import { localizationShape } from '../../../../prop-types/localization';
import { deselectAllPatients } from '../../../../../actions/patient-discovery-actions';
import { setSelectedDocType } from '../../../../../actions/query-document-actions';
import { DocTypes } from '../../../../../stores/document-store';
import DocumentResults from '../document-results/document-results';
import MedicationsResultsPanel from '../medication-results-panel/medications-results-panel';
import getMedEntitlement from '../../../../../common/util/get-med-entitlement';

import './patient-data-view.scss';

class PatientDataView extends React.Component {
    constructor(props) {
        super(props);

        this.handleViewSelect = this.handleViewSelect.bind(this);
        this.handleClearSelection = this.handleClearSelection.bind(this);

        this.state = {};
    }

    static getDerivedStateFromProps(nextProps) {
        const {
            patientResults: { patients },
        } = nextProps;

        let queryDocumentStarted = false;

        const selectedPatientIds = reduce(
            patients,
            (acc, patient) => {
                const id = get(patient, 'patientId[0].patientId');

                if (id && patient.selected) {
                    acc.push(id);

                    if (patient.status) {
                        queryDocumentStarted = true;
                    }
                }

                return acc;
            },
            [],
        );

        return {
            queryDocumentStarted,
            selectedPatientIds,
        };
    }

    componentDidMount() {
        const { hasMedEntitlement } = this.props;

        if (hasMedEntitlement) {
            this.handleViewSelect(DocTypes.MEDICATIONS);
        } else {
            this.handleViewSelect(DocTypes.DOCUMENTS);
        }
    }

    handleViewSelect(docType) {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        const { hasMedEntitlement } = this.props;

        if (!hasMedEntitlement && docType === DocTypes.MEDICATIONS) {
            return;
        }

        executeAction(setSelectedDocType, docType);
    }

    handleClearSelection() {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(deselectAllPatients);
    }

    renderViewControls() {
        const { hasMedEntitlement, localization, selectedDocType } = this.props;

        const tabs = [];

        if (hasMedEntitlement) {
            tabs.push({
                id: DocTypes.MEDICATIONS,
                heading: get(localization, DocTypes.MEDICATIONS),
            });
        }

        tabs.push({
            id: DocTypes.DOCUMENTS,
            heading: get(localization, DocTypes.DOCUMENTS),
        });

        return (
            <TabBar
                selectedTab={selectedDocType}
                tabs={tabs}
                size="lg"
                onSelect={this.handleViewSelect}
            />
        );
    }

    render() {
        const { selectedDocType, hasMedEntitlement } = this.props;
        const { queryDocumentStarted, selectedPatientIds } = this.state;

        let panel = null;

        if (selectedDocType === DocTypes.DOCUMENTS) {
            panel = (
                <DocumentResults
                    queryDocumentStarted={queryDocumentStarted}
                    selectedPatientIds={selectedPatientIds}
                />
            );
        } else if (
            selectedDocType === DocTypes.MEDICATIONS &&
            hasMedEntitlement
        ) {
            panel = <MedicationsResultsPanel />;
        }

        return (
            <PageContainer asCard allowScroll>
                <PageContainerGroup className="result-tabs-container-group">
                    {this.renderViewControls()}
                </PageContainerGroup>
                {panel}
            </PageContainer>
        );
    }
}

PatientDataView.propTypes = {
    localization: PropTypes.shape(localizationShape.documentResults).isRequired,
    selectedDocType: PropTypes.string.isRequired,
    patientResults: patientResultsPropType,
    hasMedEntitlement: PropTypes.bool.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func.isRequired,
    }).isRequired,
};

PatientDataView.defaultProps = {
    patientResults: null,
};

export default connectToStores(
    applyFluxibleContext(PatientDataView),
    [
        'DocumentStore',
        'LocalizationStore',
        'QueryStore',
        'PatientResultsStore',
        'Session',
    ],
    context => {
        const documentStore = context.getStore('DocumentStore');
        const localizationStore = context.getStore('LocalizationStore');
        const patientResultsStore = context.getStore('PatientResultsStore');

        return {
            localization: localizationStore.getState().documentResults,
            selectedDocType: documentStore.getSelectedDocType(),
            patientResults: patientResultsStore.getState(),
            hasMedEntitlement: getMedEntitlement(context),
        };
    },
);
