/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import LinkButton from '@audacious/components/components/LinkButton';
import { SimpleTable } from '@audacious/components/components/Table';
import { searchHistoryItemPropType } from '../../../../prop-types/search-history-item';

import './search-history-table.scss';

function SearchHistoryTable(props) {
    const { localization, items, onItemSelection } = props;

    const columns = [
        {
            headerBody: get(localization, 'name'),
            cellValuePath: 'formattedPatient.fullName',
            renderCell: (cellValue, rowValue) => (
                <LinkButton
                    id="searchHistorySelect"
                    className="name-btn"
                    onClick={() => onItemSelection(rowValue)}
                >
                    {cellValue}
                </LinkButton>
            ),
            sortable: true,
        },
        {
            headerBody: get(localization, 'dob'),
            cellValuePath: 'formattedPatient.dob',
            cellSortPath: 'patientDemographics.dob',
            sortable: true,
            width: '160px',
        },
        {
            headerBody: get(localization, 'gender'),
            cellValuePath: 'formattedPatient.gender',
            sortable: true,
            width: '110px',
        },
        {
            headerBody: get(localization, 'address'),
            cellValuePath: 'formattedPatient.address',
            sortable: true,
        },
        {
            headerBody: get(localization, 'phone'),
            cellValuePath: 'formattedPatient.homePhone',
            sortable: true,
        },
        {
            headerBody: get(localization, 'lastAccessed'),
            cellValuePath: 'formattedPatient.searchDate',
            cellSortPath: 'originalSearchDate',
            sortable: true,
        },
    ];

    return (
        <SimpleTable
            className="search-history-table"
            items={items}
            columns={columns}
            initialSortPath="originalSearchDate"
            initialSortDescending
            pageTable
            useParentScroll
            stickyColumns={1}
            minWidth={800}
        />
    );
}

SearchHistoryTable.propTypes = {
    localization: PropTypes.objectOf(PropTypes.string).isRequired,
    items: PropTypes.arrayOf(searchHistoryItemPropType),
    onItemSelection: PropTypes.func.isRequired,
};

SearchHistoryTable.defaultProps = {
    items: null,
};

export default SearchHistoryTable;
