import get from 'lodash/get';
import QueryType from '../../../common/query/query-type';
import log from '../../../common/log';
import QueryStatus from '../../../common/query/query-status';

function createQuerySuccess(context, { response, options, id }) {
    const { queryId, queryType, associatedIds } = options;
    log('log', `Create Query Resp: ${JSON.stringify(response)}`);

    let numExpectedResults = 1;
    if (queryType === QueryType.PATIENT_DISCOVERY) {
        numExpectedResults = get(response, 'data.numTargetOrgs', 0);
    } else if (queryType === QueryType.QUERY_DOCUMENT) {
        numExpectedResults = get(response, 'data.numPatientsQueried', 0);
    }

    let timer = setTimeout(() => {
        timer = setTimeout(() => {
            const queryStore = context.getStore('QueryStore');

            if (queryStore.getRatioComplete() < 1) {
                context.dispatch('SET_QUERY_STATUS', {
                    id: queryId,
                    timer: null,
                    status: QueryStatus.TIMEOUT,
                });

                if (queryType === QueryType.QUERY_DOCUMENT) {
                    context.dispatch('SET_QUERY_DOCUMENT_STATUS', {
                        patientIds: associatedIds,
                        isTimedOut: true,
                    });
                }

                context.socket.removeHandlers();
            }
        }, 1000 * 90);

        context.dispatch('SET_QUERY_STATUS', {
            id: queryId,
            timer,
            status: QueryStatus.DELAYED,
        });
    }, 1000 * 30);

    context.dispatch('UPDATE_QUERY', {
        id: queryId,
        numExpectedResults,
        timer,
        createQueryRequest: {
            serviceId: id,
        },
    });
}
createQuerySuccess.displayName = 'createQuerySuccess';

function createQueryError(context, { options, id, error }) {
    const { queryType, queryId, associatedIds } = options;
    log('log', `Create Query Resp ERROR: ${JSON.stringify(error)}`);

    context.dispatch('UPDATE_QUERY', {
        id: queryId,
        createQueryRequest: {
            serviceId: id,
            error,
        },
    });

    if (queryType === QueryType.QUERY_DOCUMENT) {
        context.dispatch('SET_QUERY_DOCUMENT_STATUS', {
            patientIds: associatedIds,
            isError: true,
        });
    } else if (queryType === QueryType.RETRIEVE_DOCUMENT) {
        context.dispatch('SET_RETRIEVE_DOCUMENT_STATUS', {
            isError: true,
        });
    } else if (queryType === QueryType.MEDICATIONS) {
        context.dispatch('ADD_MEDICATIONS_ERRORS', {});
    }
}
createQueryError.displayName = 'createQueryError';

export default config => ({
    serviceName: 'query.create',
    authenticate: true,
    config: {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'POST',
        url: get(config, 'endpoints.pulse.query.create'),
        // baseUrl: 'http://localhost:8080'
    },
    onSuccess: createQuerySuccess,
    onFailed: createQueryError,
    onError: createQueryError,
    onTimeout: createQueryError,
    onFatal: createQueryError,
});
