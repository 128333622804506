import merge from 'lodash/merge';

import { services as facilityServices } from '@audacious/pulse-facility-selection';
import loadDirectory from './directory/load-directory';
import createQuery from './query/create-query';
import retrieveResult from './query/retrieve-result';
import retrieveDefault from './activation/retrieve-default';
import retrieveActivations from './activation/retrieve-activations';
import retrieveActivationTargets from './activation/retrieve-activation-targets';
import retrieveActivationFacilities from './activation/retrieve-activation-facilities';
import retrieveHistoryPatientRequests from './search-history/retrieve-history-patient-requests';
import retrieveHistoryPatientResults from './search-history/retrieve-history-patient-results';
import retrieveHistoryQdRequests from './search-history/retrieve-history-qd-requests';
import retrieveHistoryQdResults from './search-history/retrieve-history-qd-results';
import retrieveHistoryRdRequests from './search-history/retrieve-history-rd-requests';
import retrieveHistoryRdResults from './search-history/retrieve-history-rd-results';
import retrieveEpraPreferences from './epra-preferences/retrieveEpraPreferences';
import endpoints from '../configs/endpoints';

const DEFAULT_CONFIG = { endpoints };

export default config => {
    const configuration = merge(DEFAULT_CONFIG, config);

    return [
        loadDirectory(configuration),
        createQuery(configuration),
        retrieveEpraPreferences(configuration),
        retrieveResult(configuration),
        retrieveDefault(configuration),
        retrieveActivations(configuration),
        retrieveActivationTargets(configuration),
        retrieveActivationFacilities(configuration),
        retrieveHistoryPatientRequests(configuration),
        retrieveHistoryPatientResults(configuration),
        retrieveHistoryQdRequests(configuration),
        retrieveHistoryQdResults(configuration),
        retrieveHistoryRdRequests(configuration),
        retrieveHistoryRdResults(configuration),
        ...facilityServices,
    ];
};
