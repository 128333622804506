import merge from 'lodash/merge';
import some from 'lodash/some';
import isNil from 'lodash/isNil';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import QueryStatus from '../common/query/query-status';

const initialState = {
    queries: {},
    originalPdRequestId: '',
};

/**
 * Maintains state of long running patient discovery, query document
 * and retrieve document queries.
 */
class QueryStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);
    }

    addQuery(newQuery) {
        const {
            id,
            type,
            associatedIds,
            socketDisconnected,
            status,
        } = newQuery;
        const state = this.getState();
        const { queries } = state;

        queries[id] = {
            id,
            type,
            associatedIds,
            status: status || QueryStatus.CREATING_QUERY,
            resultRequests: [],
        };

        if (socketDisconnected) {
            queries[id].status = QueryStatus.SOCKET_DISCONNECTED;
            // eslint-disable-next-line no-console
            console.error('Socket Disconnected');
        }

        state.queries = queries;
        this.setState(state);
    }

    updateQuery(args) {
        const { id, numExpectedResults, createQueryRequest, timer } = args;
        const state = this.getState();
        const { queries } = state;
        const query = queries[id];

        if (query) {
            query.numExpectedResults = numExpectedResults || 0;
            query.createQueryRequest = createQueryRequest || {};

            if (createQueryRequest.error) {
                query.status = QueryStatus.REQUEST_ERROR;
                query.errorResponse = createQueryRequest.error?.response?.data;
            } else if (query.status === QueryStatus.CREATING_QUERY) {
                query.status = QueryStatus.RETRIEVING_RESULTS;
            }

            query.timer = timer;

            state.queries = queries;
            this.setState(state);
        }
    }

    addResult(args) {
        const { id, resultRequest, requestError = false } = args;
        const state = this.getState();
        const { queries } = state;
        const query = queries[id];

        if (query) {
            query.resultRequests.push(resultRequest);

            if (requestError) {
                query.status = QueryStatus.REQUEST_ERROR;
            }

            if (query.resultRequests.length >= query.numExpectedResults) {
                if (!isNil(query.timer)) {
                    clearTimeout(query.timer);
                }

                query.timer = null;

                if (
                    query.status === QueryStatus.RETRIEVING_RESULTS ||
                    query.status === QueryStatus.DELAYED
                ) {
                    query.status = QueryStatus.COMPLETE;
                }
            }

            state.queries = queries;
            this.setState(state);
        }
    }

    removeQueries() {
        const state = this.getState();

        forEach(state.queries, query => {
            if (!isNil(query.timer)) {
                clearTimeout(query.timer);
            }
        });

        state.queries = {};

        this.setState(state);
    }

    setStatus({ id, ...rest }) {
        const state = this.getState();

        const { queries } = state;

        const query = queries[id];

        if (isNil(query)) {
            return;
        }

        queries[id] = merge({}, query, rest);

        this.setState(state);
    }

    isComplete(id) {
        const { queries } = this.state;
        const query = queries[id];

        return query && query.status === QueryStatus.COMPLETE;
    }

    isRetrievingResults(id) {
        const { queries } = this.state;
        const query = queries[id];

        return (
            query &&
            (query.status === QueryStatus.RETRIEVING_RESULTS ||
                query.status === QueryStatus.CREATING_QUERY ||
                query.status === QueryStatus.DELAYED)
        );
    }

    doesQueryOfTypeExist(type) {
        const { queries } = this.state;

        const typeExists = some(queries, query => query.type === type);

        return typeExists;
    }

    /** Returns true if all queries of the given type have completed successfully */
    isTypeComplete(type) {
        const { queries } = this.state;

        if (isEmpty(queries)) {
            return false;
        }

        const incomplete = some(
            queries,
            query =>
                query.type === type && query.status !== QueryStatus.COMPLETE,
        );

        return !incomplete;
    }

    /** Returns true if queries of the given type have errored, including the socket */
    isTypeError(type) {
        const { queries } = this.state;

        if (isEmpty(queries)) {
            return false;
        }

        const errored = some(
            queries,
            query =>
                query.type === type &&
                (query.status === QueryStatus.REQUEST_ERROR ||
                    query.status === QueryStatus.SOCKET_DISCONNECTED),
        );

        return errored;
    }

    getErrorField(type) {
        const { queries } = this.state;

        if (isEmpty(queries)) {
            return false;
        }

        const erroredQuery = find(
            queries,
            query =>
                query.type === type &&
                (query.status === QueryStatus.REQUEST_ERROR ||
                    query.status === QueryStatus.SOCKET_DISCONNECTED),
        );

        if (erroredQuery?.errorResponse) {
            const responseData = erroredQuery.errorResponse;
            const jsonStartIndex = responseData?.indexOf('{');

            if (jsonStartIndex === -1) {
                return null;
            }

            const jsonString = responseData.substring(jsonStartIndex);

            try {
                const errorResponse = JSON.parse(jsonString);
                return errorResponse?.fieldName || null;
            } catch (e) {
                return null;
            }
        }
        return null;
    }

    /** Returns true if all queries of the given type completed successfully or errored */
    isTypeLoading(type) {
        const { queries } = this.state;

        const loading = some(
            queries,
            query =>
                query.type === type &&
                (query.status === QueryStatus.CREATING_QUERY ||
                    query.status === QueryStatus.RETRIEVING_RESULTS ||
                    query.status === QueryStatus.DELAYED),
        );

        return loading;
    }

    isDelayed(type) {
        const { queries } = this.state;

        const loading = some(
            queries,
            query =>
                query.type === type && query.status === QueryStatus.DELAYED,
        );

        return loading;
    }

    isTimedOut(type) {
        const { queries } = this.state;

        const loading = some(
            queries,
            query =>
                query.type === type && query.status === QueryStatus.TIMEOUT,
        );

        return loading;
    }

    getRatioComplete(id) {
        const { queries } = this.state;
        const query = queries[id];

        return query && query.numExpectedResults > 0
            ? query.resultRequests.length / query.numExpectedResults
            : 0;
    }

    getRatioOfTypeComplete(type) {
        const { queries } = this.state;
        let totalExpectedResults = 0;
        let totalResults = 0;

        forEach(Object.values(queries), query => {
            if (query.type === type) {
                totalResults += query.resultRequests.length;
                totalExpectedResults += query.numExpectedResults;
            }
        });

        return totalExpectedResults > 0
            ? totalResults / totalExpectedResults
            : 0;
    }

    getQueries() {
        const { queries } = this.getState();
        return queries;
    }

    getQuery(id) {
        const { queries } = this.getState();
        return queries[id];
    }

    setOriginalPdRequestId(id) {
        const state = this.getState();
        state.originalPdRequestId = id;
        this.setState(state);
    }

    getOriginalPdRequestId() {
        return this.getState().originalPdRequestId || '';
    }
}

QueryStore.storeName = 'QueryStore';
QueryStore.handlers = {
    ADD_QUERY: 'addQuery',
    UPDATE_QUERY: 'updateQuery',
    ADD_QUERY_RESULT: 'addResult',
    REMOVE_QUERIES: 'removeQueries',
    SET_PD_REQUEST_ID: 'setOriginalPdRequestId',
    LOGOUT: 'resetState',
    SET_QUERY_STATUS: 'setStatus',
};

export default QueryStore;
