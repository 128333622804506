import PropTypes from 'prop-types';

export const documentShape = PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    source: PropTypes.string,
    serviceTime: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    patientId: PropTypes.object.isRequired,
}).isRequired;

export const documentResultsPropType = PropTypes.shape({
    documents: PropTypes.arrayOf(documentShape),
}).isRequired;
