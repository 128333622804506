import React from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import CcdView from '@audacious/components/components/CCDView';
import PDFViewer from '@audacious/components/components/PDFViewer';
import Modal from '@audacious/components/components/Modal';
import { documentViewPropType } from '../../../../prop-types/document-view';
import { endViewingDocument } from '../../../../../actions/retrieve-document-actions';

class DocumentView extends React.Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(endViewingDocument);
    }

    render() {
        const { documentViewResults, userName, allowPrint } = this.props;
        const documentData = get(documentViewResults, 'data', null);
        const documentType = get(documentViewResults, 'type', null);

        const selectedDocument = get(
            documentViewResults,
            'selectedDocument',
            null,
        );

        const documentMeta = {};
        documentMeta.name = get(selectedDocument, 'name');
        documentMeta.sourceFacility = get(
            selectedDocument,
            'classification.facility',
        );
        documentMeta.sourceDepartment = get(
            selectedDocument,
            'classification.department',
        );
        documentMeta.serviceStartTime = get(
            selectedDocument,
            'serviceStartTime',
        );
        documentMeta.serviceStopTime = get(selectedDocument, 'serviceStopTime');
        documentMeta.authorName = get(selectedDocument, 'authors[0].person');
        documentMeta.authorDetail = get(selectedDocument, 'authorDetail');
        documentMeta.authorInstitution = get(
            selectedDocument,
            'authors[0].institutions[0]',
        );
        documentMeta.documentType = get(
            selectedDocument,
            'classification.type',
        );

        if (documentType === 'html') {
            return (
                <CcdView
                    documentHtml={documentData}
                    documentMeta={documentMeta}
                    open={!isNil(documentData)}
                    userName={userName}
                    allowPrint={allowPrint}
                    onClose={this.handleClose}
                />
            );
        }

        if (documentType === 'pdf') {
            const file = {
                name: 'report.pdf',
                data: documentData,
            };

            return (
                <Modal
                    id="pdf-view-modal"
                    className="document-view-container"
                    open={!isNil(documentData)}
                    variant="modal"
                    size="xl"
                    isLoading={isNil(documentData)}
                    fitVertically
                >
                    <PDFViewer
                        file={file}
                        defaultScale={PDFViewer.SCALE.FIT_TO_SCREEN}
                        toolbar={{
                            enabled: true,
                            options: {
                                paging: {
                                    enabled: true,
                                    options: {
                                        step: true,
                                        goto: true,
                                    },
                                },
                                zoom: true,
                                print: true,
                                download: true,
                                close: true,
                            },
                        }}
                        onClose={this.handleClose}
                    />
                </Modal>
            );
        }

        return null;
    }
}

DocumentView.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    documentViewResults: PropTypes.shape(documentViewPropType).isRequired,
    userName: PropTypes.string.isRequired,
    allowPrint: PropTypes.bool.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func.isRequired,
    }).isRequired,
};

export default connectToStores(
    applyFluxibleContext(DocumentView),
    [
        'DocumentViewStore',
        'LocalizationStore',
        'Session',
        'EpraPreferencesStore',
    ],
    context => {
        const documentViewStore = context.getStore('DocumentViewStore');
        // const localizationStore = context.getStore('LocalizationStore');
        const sessionStore = context.getStore('Session');
        const user = sessionStore.getUser();
        const userName = isNil(user) ? '' : user.getFullName();
        const epraPreferencesState = context
            .getStore('EpraPreferencesStore')
            .getState();

        const allowPrint = get(
            epraPreferencesState,
            'preferences.allowPrint',
            false,
        );

        return {
            documentViewResults: documentViewStore.getState(),
            // localization: localizationStore.getState().documentView,
            userName,
            allowPrint,
        };
    },
);
