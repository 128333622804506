import get from 'lodash/get';
import { finishQueryIfComplete } from '../../../common/query/query-helper';
import QueryType from '../../../common/query/query-type';
import log from '../../../common/log';

function retrieveResultSuccess(context, { response, options, id }) {
    const {
        data: { results = [], oid },
    } = response;
    const { queryType, queryId, associatedIds } = options;
    log('log', `Retrieve Result Resp: ${JSON.stringify(response)}`);

    finishQueryIfComplete(context, queryId);

    const error = get(response, 'data.error', {});

    if (queryType === QueryType.PATIENT_DISCOVERY) {
        context.dispatch('ADD_PATIENT_RESULT', { results, oid });
    } else if (queryType === QueryType.QUERY_DOCUMENT) {
        context.dispatch('ADD_QUERY_DOCUMENT_RESULT', { results, oid });
        context.dispatch('SET_QUERY_DOCUMENT_STATUS', {
            results,
            oid,
            patientIds: associatedIds,
            retrievedResult: true,
            isError: error.returnedError,
        });
    } else if (queryType === QueryType.RETRIEVE_DOCUMENT) {
        const contentType = response.headers.getContentType();
        let type;
        const data = get(response, 'data');

        if (contentType.includes('application/pdf')) {
            type = 'pdf';
        } else if (contentType.includes('text/html')) {
            type = 'html';
        } else {
            throw new Error('Invalid data type');
        }

        context.dispatch('SET_RETRIEVE_DOCUMENT_STATUS', {
            results,
            isError: error.returnedError,
        });
        context.dispatch('UPDATE_TRANSFORMED_DOCUMENT', {
            type,
            data,
        });
    } else if (queryType === QueryType.MEDICATIONS) {
        context.dispatch('ADD_MEDICATIONS_RESULTS', {
            results,
            error: error.returnedError,
        });
    } else {
        throw new Error('Invalid Query Type');
    }

    const resultRequest = {
        serviceId: id,
        results,
    };

    if (error.returnedError) {
        resultRequest.error = error.error;
    }

    context.dispatch('ADD_QUERY_RESULT', {
        id: queryId,
        resultRequest,
    });
}

retrieveResultSuccess.displayName = 'retrieveResultSuccess';

function retrieveResultError(context, { options, id, error }) {
    const { queryType, queryId, associatedIds } = options;
    log('log', `Retrieve Result Resp ERROR: ${JSON.stringify(error)}`);

    finishQueryIfComplete(context, queryId);

    context.dispatch('ADD_QUERY_RESULT', {
        id: queryId,
        resultRequest: {
            serviceId: id,
            error,
        },
        requestError: true,
    });

    if (queryType === QueryType.QUERY_DOCUMENT) {
        context.dispatch('SET_QUERY_DOCUMENT_STATUS', {
            patientIds: associatedIds,
            isError: true,
        });
    } else if (queryType === QueryType.RETRIEVE_DOCUMENT) {
        context.dispatch('SET_RETRIEVE_DOCUMENT_STATUS', {
            isError: true,
        });
    }
}
retrieveResultError.displayName = 'retrieveResultError';

export default config => ({
    serviceName: 'query.retrieveResult',
    authenticate: true,
    config: {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET',
        url: get(config, 'endpoints.pulse.query.retrieveResult'),
        // baseUrl: 'http://localhost:8080'
    },
    onSuccess: retrieveResultSuccess,
    onFailed: retrieveResultError,
    onError: retrieveResultError,
    onTimeout: retrieveResultError,
    onFatal: retrieveResultError,
});
