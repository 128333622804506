const QueryStatus = {
    CREATING_QUERY: 'creatingQuery',
    RETRIEVING_RESULTS: 'retrievingResults',
    DELAYED: 'delayed',
    COMPLETE: 'complete',
    SOCKET_DISCONNECTED: 'socketDisconnected',
    REQUEST_ERROR: 'requestError',
    TIMEOUT: 'timeout',
};

export default QueryStatus;
