import PropTypes from 'prop-types';

export const PatientFieldsShape = {
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    firstName: PropTypes.string,
    dob: PropTypes.date,
    gender: PropTypes.string,
    zip: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.object,
    ssn: PropTypes.string,
    homePhone: PropTypes.string,
};

export const PatientFieldsPropType = PropTypes.shape(PatientFieldsShape);
