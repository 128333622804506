import get from 'lodash/get';

function retrieveActivationsSuccess(context, { response }) {
    const activations = response.data;
    context.dispatch('SET_ACTIVATIONS', activations);

    // clear existing activation targets

    const userStore = context.getStore('UserStore');
    const userState = userStore.getState();
    const tenantId = get(userState, 'user.tenantId', '');

    // get activation targets for each active activation
    activations.forEach(activation => {
        if (activation.active) {
            const { activationId } = activation;
            const options = { activationId, tenantId };

            context.service.tenant.activation.retrieveActivationFacilities({
                options,
            });

            // context.service.tenant.activation.retrieveActivationTargets({
            //     options,
            // });
        }
    });
}
retrieveActivationsSuccess.displayName = 'retrieveActivationsSuccess';

export default config => ({
    serviceName: 'tenant.activation.retrieveActivations',
    authenticate: true,
    config: {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET',
        url: get(config, 'endpoints.pulse.tenant.activation.retrieve'),
    },
    onSuccess: retrieveActivationsSuccess,
});
