import get from 'lodash/get';

function retrieveDefaultSuccess(context, { response }) {
    const {
        data: { activationId },
    } = response;

    context.dispatch('SET_ACTIVATION', activationId);
}
retrieveDefaultSuccess.displayName = 'retrieveDefaultSuccess';

export default config => ({
    serviceName: 'loadDefaultActivation',
    authenticate: true,
    config: {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        method: 'GET',
        url: get(config, 'endpoints.pulse.tenant.activation.retrieveDefault'),
    },
    onSuccess: retrieveDefaultSuccess,
});
