import PropTypes from 'prop-types';

export const searchHistoryItemShape = {
    originalSearchDate: PropTypes.string.isRequired,
    patientDemographics: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        middleName: PropTypes.string,
        suffix: PropTypes.string,
        gender: PropTypes.string,
        dob: PropTypes.string,
        address1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        homePhone: PropTypes.string,
    }),
};

export const searchHistoryItemPropType = PropTypes.shape(
    searchHistoryItemShape,
);
